import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { Edit2, ArrowLeft } from 'react-feather';
import { GetPhoto } from '../../store/actions/imgActions';
import { isMobile } from 'react-device-detect';
import firebase from 'firebase/compat/app';

import moment from 'moment';
import 'moment/locale/nl';
import EditImageModal from '../models/EditImageModal';

// import AddToCalendarHOC from 'react-add-to-calendar-hoc';
// import { getFirstPart, getSecondPart } from '../../functions/stringSplitting';

moment.locale('nl');

const EventDetails = (props) => {
  const { evenement } = props;

  // const ATCDropdown = ({ children }) => <div>{children}</div>;

  // const ATCButton = ({ children, onClick }) => (
  //   <button onClick={onClick} className="btn-icon">
  //     {children}
  //   </button>
  // );

  // const AddToCalendarDropdown = AddToCalendarHOC(ATCButton, ATCDropdown);

  const [img, setImg] = useState(
    'https://firebasestorage.googleapis.com/v0/b/eventplanning-c2a4f.appspot.com/o/images%2Fdefault.jpg?alt=media&token=1950fb65-663d-4157-8cd6-4289408cb565'
  );

  const [factuur, setFactuur] = useState([]);

  const handleBack = (e) => {
    e.preventDefault();
    window.history.back();
  };

  const handleblur = (e) => {
    document.getElementById('blur').remove();
  };

  const setFactuurData = (organ) => {
    var factuurRef = firebase.firestore().collection('facturen');
    var query = factuurRef.where('title', '==', organ);

    if (factuur.length === 0) {
      query.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const factuurData = doc.data();
          setFactuur(factuurData);
        });
      });
    }
  };

  useEffect(() => {
    if (evenement) {
      setFactuurData(evenement.organisator);

      if (evenement.img !== 'images/undefined') {
        GetPhoto(evenement.img)?.then((res) => {
          setImg(res);
        });
      } else {
        return null;
      }
    }
    // eslint-disable-next-line
  });

  if (evenement !== null) {
    //   const startDatetime = null;
    //   const endDatetime = null;
    //   if (evenement.showtijden) {
    //     startDatetime =
    //       moment(evenement.startdatum.toDate()).format('YYYYMMDD') +
    //       'T' +
    //       getFirstPart(evenement.showtijden[0].begintijd, ':') +
    //       getSecondPart(evenement.showtijden[0].begintijd, ':') +
    //       '00';

    //     endDatetime =
    //       moment(evenement.einddatum.toDate()).format('YYYYMMDD') +
    //       'T' +
    //       getFirstPart(
    //         evenement.showtijden[evenement.showtijden.length - 1].eindtijd,
    //         ':'
    //       ) +
    //       getSecondPart(
    //         evenement.showtijden[evenement.showtijden.length - 1].eindtijd,
    //         ':'
    //       ) +
    //       '00';
    //   }

    //   const duration = moment
    //     .duration(moment(endDatetime).diff(moment(startDatetime)))
    //     .asHours();

    return (
      <div className="container section project-details">
        <button className="btn-icon back-btn" onClick={handleBack}>
          <span>
            <ArrowLeft size={20} />
          </span>
          Back
        </button>
        <div className="header">
          <img className="header-img" src={img} alt="event"></img>
          {isMobile === false && (
            <>
              {props.profile.role !== 'gast' && (
                <button
                  className="editImg"
                  onClick={() => {
                    const modal = document.getElementById('imageEditModal');
                    modal.style.display = 'block';
                  }}
                >
                  <Edit2 size={20} />
                </button>
              )}
            </>
          )}
          {factuur.length !== 0 && (
            <div className="factuur-info">
              <h4>Factuur gegevens</h4>
              <p>{factuur.title}</p>
              <p>{factuur.adres}</p>
              <p>
                KvK: {factuur.kvk}, BTW: {factuur.btw}
              </p>
              {factuur.bank && <p>Bankrekeningnummer: {factuur.bank}</p>}
              {factuur.facPdf && (
                <p>
                  Facturen mogen als pdf naar:{' '}
                  <a href={'mailto:' + factuur.facPdf}>{factuur.facPdf}</a>
                </p>
              )}
              {factuur.facVra && (
                <p>
                  Vragen mogen naar:{' '}
                  <a href={'mailto:' + factuur.facVra}>{factuur.facVra}</a>
                </p>
              )}
            </div>
          )}
        </div>
        <EditImageModal event={evenement} />
        <div className="head-content">
          <div>
            <h1>{evenement.projectnaam}</h1>
            {evenement.status === 'Bevestigd' && (
              <p className="status-block confirmed">{evenement.status}</p>
            )}
            {evenement.status === 'Concept' && (
              <p className="status-block concept">{evenement.status}</p>
            )}
            {evenement.status === 'Cancelled' && (
              <p className="status-block cancelled">{evenement.status}</p>
            )}
            {evenement.status === 'Feestdag' && (
              <p className="status-block feestdag">{evenement.status}</p>
            )}
          </div>
          <div>
            {/* <AddToCalendarDropdown
              linkProps={{
                className: 'atc-dropdown-title',
              }}
              event={{
                title: evenement.projectnaam,
                startDatetime,
                endDatetime, //TODO: +1 day end date if end time is before start time
                duration,
                timezone: 'Europe/Amsterdam',
              }}
            /> */}
            {isMobile === false && (
              <>
                {props.profile.role !== 'gast' && (
                  <Link
                    to={'/update/' + props.match.params.id}
                    className="event-add"
                  >
                    <p>Evenement bewerken</p>
                    <span>
                      <Edit2 size={20} />
                    </span>
                  </Link>
                )}
              </>
            )}
          </div>
        </div>
        <div className="main-content">
          {props.profile.role !== 'gast' && (
            <>
              {evenement.draaiboeklink && (
                <div className="info-item" id="draaiboek">
                  <h3>DRAAIBOEK</h3>
                  <p>
                    Gebruik onderstaande knop om naar het draaiboek te gaan.{' '}
                    <br /> Het draaiboek is vanaf nu leidend voor alle
                    informatie!
                  </p>
                  <div className="draaiboek-btns">
                    <a
                      href={evenement.draaiboeklink}
                      target="_blank"
                      rel="noreferrer"
                      className="draaiboek-btn"
                    >
                      Ga naar Draaiboek
                    </a>
                    <button className="button-alt" onClick={handleblur}>
                      Bekijk onderstaande info
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
          <div className="info-container">
            {props.profile.role !== 'gast' && (
              <>{evenement.draaiboeklink && <div id="blur"></div>}</>
            )}
            {evenement.showtijden !== null && (
              <div className="main-content show-times">
                <h6 className="info-title">SHOWDAGEN</h6>
                <table className="striped">
                  <thead>
                    <tr>
                      <th>Weekdag</th>
                      <th>Showdatum</th>
                      <th>Start showtijd</th>
                      <th>Einde showtijd</th>
                      <th>Verwachte aantal bezoekers</th>
                    </tr>
                  </thead>
                  <tbody>
                    {evenement.showtijden.map((showdag) => {
                      return (
                        <tr>
                          {typeof showdag.datum !== 'number' && (
                            <td>
                              {moment(showdag.datum.toDate()).format('dddd')}
                            </td>
                          )}
                          {typeof showdag.datum === 'number' && (
                            <td>{moment(showdag.datum).format('dddd')}</td>
                          )}
                          {typeof showdag.datum !== 'number' && (
                            <td>
                              {moment(showdag.datum.toDate()).format(
                                'DD-MM-yyy'
                              )}
                            </td>
                          )}
                          {typeof showdag.datum === 'number' && (
                            <td>{moment(showdag.datum).format('DD-MM-yyy')}</td>
                          )}
                          <td>{showdag.begintijd}</td>
                          <td>{showdag.eindtijd}</td>
                          <td>{showdag.estcapacity}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
            <div className="info-section">
              <h6 className="info-title">ALGEMEEN</h6>
              <div className="info-item">
                <label>Organisator</label>
                {factuur.length !== 0 ? (
                  <p>{factuur.title}</p>
                ) : (
                  <p>{evenement.organisator}</p>
                )}
              </div>
              <div className="info-item">
                <label>Partner</label>
                <p>{evenement.partner}</p>
              </div>
              {typeof evenement.startdatum !== 'number' && (
                <div className="info-item">
                  <label>Startdatum</label>
                  <p>
                    {moment(evenement.startdatum.toDate()).format('DD-MM-yyy')}
                  </p>
                </div>
              )}
              {typeof evenement.startdatum === 'number' && (
                <div className="info-item">
                  <label>Startdatum</label>
                  <p>{moment(evenement.startdatum).format('DD-MM-yyy')}</p>
                </div>
              )}
              {typeof evenement.einddatum !== 'number' && (
                <div className="info-item">
                  <label>Einddatum</label>
                  <p>
                    {moment(evenement.einddatum.toDate()).format('DD-MM-yyy')}
                  </p>
                </div>
              )}
              {typeof evenement.einddatum === 'number' && (
                <div className="info-item">
                  <label>Einddatum</label>
                  <p>{moment(evenement.einddatum).format('DD-MM-yyy')}</p>
                </div>
              )}
              <div className="info-item">
                <label>Adres</label>
                <p>{evenement.adres}</p>
              </div>
              <div className="info-item">
                <label>Capaciteit</label>
                <p>{evenement.capaciteit}</p>
              </div>
              <div className="info-item">
                <label style={{ color: 'green' }}>Duurzaamheid</label>
                <p style={{ color: 'green' }}>{evenement.duurzaamheid}</p>
              </div>
              <div className="info-item">
                <label>opmerkingen</label>
                <p>{evenement.opmerkingen}</p>
              </div>
            </div>
            <h6 className="info-title">PROJECT TEAM</h6>
            <div className="info-section">
              <div className="info-item">
                <label>projecteigenaar</label>
                <p>{evenement.projecteigenaar}</p>
              </div>
              <div className="info-item">
                <label>project manager</label>
                <p>{evenement.projectmanager}</p>
              </div>
              <div className="info-item">
                <label>productie manager</label>
                <p>{evenement.productiemanager}</p>
              </div>
              <div className="info-item">
                <label>marketing manager</label>
                <p>{evenement.marketingmanager}</p>
              </div>
              <div className="info-item">
                <label>horeca manager</label>
                <p>{evenement.horecamanager}</p>
              </div>
              <div className="info-item">
                <label>programmeur</label>
                <p>{evenement.programmeur}</p>
              </div>
              <div className="info-item">
                <label>Finance manager</label>
                <p>{evenement.financemanager}</p>
              </div>
              <div className="info-item">
                <label>Finance &#38; ticketing manager</label>
                <p>{evenement.financeticketingmanager}</p>
              </div>
              <div className="info-item">
                <label>administratie</label>
                <p>{evenement.administratie}</p>
              </div>
              <div className="info-item">
                <label>Event manager</label>
                <p>{evenement.eventmanager}</p>
              </div>
              <div className="info-item">
                <label>leverancier crew show</label>
                <p>{evenement.leveranciercrew}</p>
              </div>
            </div>
            <h6 className="info-title">HORECA</h6>
            <div className="info-section">
              <div className="info-item">
                <label>Horeca manager (show)</label>
                <p>{evenement.horecamanagershow}</p>
              </div>
              <div className="info-item">
                <label>Leverancier barpersoneel</label>
                <p>{evenement.barleverancier}</p>
              </div>
              <div className="info-item">
                <label>Brouwerij</label>
                <p>{evenement.brouwerij}</p>
              </div>
              <div className="info-item">
                <label>Leverancier restpakket</label>
                <p>{evenement.restleverancier}</p>
              </div>
              <div className="info-item">
                <label>Beverage - Assortiment</label>
                <p>{evenement.beverageassortiment}</p>
              </div>
              <div className="info-item">
                <label>Beverage - Activaties</label>
                <p>{evenement.beverageactivaties}</p>
              </div>
              <div className="info-item">
                <label>Beverage - Specials bier op tap</label>
                <p>{evenement.beveragespecialsbier}</p>
              </div>
              <div className="info-item">
                <label>Beverage - T-shirts</label>
                <p>{evenement.beverageshirt}</p>
              </div>
              <div className="info-item">
                <label>Muntprijs</label>
                <p>{evenement.muntprijs}</p>
              </div>
              <div className="info-item"></div>
              {evenement.horecaopbouw && (
                <div className="info-item-big">
                  <h6 className="label">HORECA OPBOUW</h6>
                  <table className="striped">
                    <thead>
                      <tr>
                        <th>Opbouwdag</th>
                        <th>Vanaf</th>
                        <th>Tot</th>
                        <th>Medewerkers</th>
                      </tr>
                    </thead>
                    <tbody>
                      {evenement.horecaopbouw.map((dag) => {
                        return (
                          <tr>
                            {typeof dag.hopdatum !== 'number' && (
                              <td>
                                {moment(dag.hopdatum.toDate()).format(
                                  'DD-MM-yyy'
                                )}
                              </td>
                            )}
                            {typeof dag.hopdatum === 'number' && (
                              <td>
                                {moment(dag.hopdatum).format('DD-MM-yyy')}
                              </td>
                            )}
                            <td>{dag.hopvanaf}</td>
                            <td>{dag.hoptot}</td>
                            <td>{dag.hopmedewerker}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
              {evenement.horecashow && (
                <div className="info-item-big">
                  <h6 className="label">HORECA SHOW</h6>
                  <table className="striped">
                    <thead>
                      <tr>
                        <th>Showdag</th>
                        <th>Vanaf</th>
                        <th>Tot</th>
                        <th>Medewerkers</th>
                      </tr>
                    </thead>
                    <tbody>
                      {evenement.horecashow.map((dag) => {
                        return (
                          <tr>
                            {typeof dag.hsdatum !== 'number' && (
                              <td>
                                {moment(dag.hsdatum.toDate()).format(
                                  'DD-MM-yyy'
                                )}
                              </td>
                            )}
                            {typeof dag.hsdatum === 'number' && (
                              <td>{moment(dag.hsdatum).format('DD-MM-yyy')}</td>
                            )}
                            <td>{dag.hsvanaf}</td>
                            <td>{dag.hstot}</td>
                            <td>{dag.hsmedewerker}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
              {evenement.horecaafbouw && (
                <div className="info-item-big">
                  <h6 className="label">HORECA AFBOUW</h6>
                  <table className="striped">
                    <thead>
                      <tr>
                        <th>Afbouwdag</th>
                        <th>Vanaf</th>
                        <th>Tot</th>
                        <th>Medewerkers</th>
                      </tr>
                    </thead>
                    <tbody>
                      {evenement.horecaafbouw.map((dag) => {
                        return (
                          <tr>
                            {typeof dag.hafdatum !== 'number' && (
                              <td>
                                {moment(dag.hafdatum.toDate()).format(
                                  'DD-MM-yyy'
                                )}
                              </td>
                            )}
                            {typeof dag.hafdatum === 'number' && (
                              <td>
                                {moment(dag.hafdatum).format('DD-MM-yyy')}
                              </td>
                            )}
                            <td>{dag.hafvanaf}</td>
                            <td>{dag.haftot}</td>
                            <td>{dag.hafmedewerker}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
              {evenement.cateraar && (
                <div className="info-item-big">
                  <h6 className="label">CATERAARS</h6>
                  <table className="striped">
                    <thead>
                      <tr>
                        <th>Naam</th>
                        <th>Telefoonnummer</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {evenement.cateraar.map((caterer) => {
                        return (
                          <tr>
                            <td>{caterer.cateraarnaam}</td>
                            <td>
                              <a href={'tel:' + caterer.cateraartel}>
                                <p>{caterer.cateraartel}</p>
                              </a>
                            </td>
                            <td>
                              <a href={'mailto:' + caterer.cateraarmail}>
                                <p>{caterer.cateraarmail}</p>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            {evenement.locatiemanager && (
              <>
                <h6 className="info-title">LOCATIE</h6>
                <div className="info-section">
                  <div className="info-item-big">
                    <h6 className="label">CONTACTPERSONEN LOCATIE</h6>
                    <table className="striped">
                      <thead>
                        <tr>
                          <th>Naam</th>
                          <th>Telefoonnummer</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {evenement.locatiemanager.map((manager) => {
                          return (
                            <tr>
                              <td>{manager.locmannaam}</td>
                              <td>
                                <a href={'tel:' + manager.locmantel}>
                                  <p>{manager.locmantel}</p>
                                </a>
                              </td>
                              <td>
                                <a href={'mailto:' + manager.locmanmail}>
                                  <p>{manager.locmanmail}</p>
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
            <h6 className="info-title">VEILIGHEID & VERGUNNINGEN</h6>
            <div className="info-section">
              <div className="info-item">
                <label>Wetgeving</label>
                <p>{evenement.wetgeving}</p>
              </div>
              <div className="info-item">
                <label>Beveiligingspartij</label>
                <p>{evenement.beveiliging}</p>
              </div>
              <div className="info-item">
                <label>Medische dienst</label>
                <p>{evenement.medisch}</p>
              </div>
            </div>
            <h6 className="info-title">PRODUCTIE</h6>
            <div className="info-section">
              <div className="info-item">
                <label>Transport</label>
                <p>{evenement.transport}</p>
              </div>
              <div className="info-item">
                <label>Tourset</label>
                <p>{evenement.tourset}</p>
              </div>
              <div className="info-item">
                <label>Decor / Technische Producent</label>
                <p>{evenement.decorproducent}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container center">
        <p>Evenement laden....</p>
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const evenementen = state.firestore.data.evenementen;
  const facturen = state.firestore.data.facturen;
  const evenement = evenementen ? evenementen[id] : null;
  return {
    evenement: evenement,
    profile: state.firebase.profile,
    facturen: facturen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetPhoto: (path) => dispatch(GetPhoto(path)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    {
      collection: 'evenementen',
    },
    {
      collection: 'facturen',
    },
  ])
)(EventDetails);
