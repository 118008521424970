import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createEvenement } from '../../store/actions/evenementActions.js';
import { Plus } from 'react-feather';
import { Redirect } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { ArrowLeft } from 'react-feather';
import { Timestamp } from 'firebase/firestore';
import firebase from 'firebase/compat';

const notify = () =>
  toast.success('Evenement is aangemaakt, je wordt automatisch doorgestuurd.');

class CreateEvenement extends Component {
  state = {
    projectnaam: '',
    draaiboeklink: null,
    startdatum: null,
    einddatum: null,
    organisator: '',
    partner: 'NVT',
    plaats: 'NVT',
    venue: null,
    capaciteit: null,
    adres: null,
    status: '',
    projecteigenaar: null,
    projectmanager: null,
    marketingmanager: null,
    leveranciercrew: null,
    productiemanager: null,
    horecamanager: null,
    programmeur: null,
    financemanager: null,
    financeticketingmanager: null,
    administratie: null,
    eventmanager: null,
    opmerkingen: null,
    img: 'images/default.jpg',
    showtijden: null,
    horecamanagershow: null,
    barleverancier: null,
    brouwerij: null,
    restleverancier: null,
    beveiliging: null,
    medisch: null,
    wetgeving: null,
    locatiemanager: null,
    transport: null,
    tourset: null,
    beverageassortiment: null,
    beverageactivaties: null,
    beveragespecialsbier: null,
    beverageshirt: null,
    muntprijs: null,
    horecaopbouw: null,
    horecashow: null,
    horecaafbouw: null,
    cateraar: null,
    decorproducent: null,
    factuuren: [],
    duurzaamheid: null,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleRadioChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleBack = (e) => {
    e.preventDefault();
    window.history.back();
  };

  dateChange = (e) => {
    let date = new Date(e.target.value + 'T00:00:00');
    let fbTimestamp = Timestamp.fromDate(date);
    this.setState({
      [e.target.id]: fbTimestamp,
    });
  };

  showDateChange = (e) => {
    let showtijden = [...this.state.showtijden];
    let date = new Date(e.target.value + 'T00:00:00');
    let fbTimestamp = Timestamp.fromDate(date);

    showtijden[e.target.dataset.id][e.target.classList[0]] = fbTimestamp;
    this.setState({ showtijden });
  };

  showTimeChange = (e) => {
    let showtijden = [...this.state.showtijden];
    showtijden[e.target.dataset.id][e.target.classList[0]] = e.target.value;
    this.setState({ showtijden });
  };

  locManChange = (e) => {
    let locatiemanager = [...this.state.locatiemanager];
    locatiemanager[e.target.dataset.id][e.target.classList[0]] = e.target.value;
    this.setState({ locatiemanager });
  };

  cateraarChange = (e) => {
    let cateraar = [...this.state.cateraar];
    cateraar[e.target.dataset.id][e.target.classList[0]] = e.target.value;
    this.setState({ cateraar });
  };

  horecaOpbouwDateChange = (e) => {
    let horecaopbouw = [...this.state.horecaopbouw];
    let date = new Date(e.target.value + 'T00:00:00');
    let fbTimestamp = Timestamp.fromDate(date);

    horecaopbouw[e.target.dataset.id][e.target.classList[0]] = fbTimestamp;
    this.setState({ horecaopbouw });
  };

  horecaOpbouwTimeChange = (e) => {
    let horecaopbouw = [...this.state.horecaopbouw];
    horecaopbouw[e.target.dataset.id][e.target.classList[0]] = e.target.value;
    this.setState({ horecaopbouw });
  };

  horecaShowDateChange = (e) => {
    let horecashow = [...this.state.horecashow];
    let date = new Date(e.target.value + 'T00:00:00');
    let fbTimestamp = Timestamp.fromDate(date);

    horecashow[e.target.dataset.id][e.target.classList[0]] = fbTimestamp;
    this.setState({ horecashow });
  };

  horecaShowTimeChange = (e) => {
    let horecashow = [...this.state.horecashow];
    horecashow[e.target.dataset.id][e.target.classList[0]] = e.target.value;
    this.setState({ horecashow });
  };

  horecaAfbouwDateChange = (e) => {
    let horecaafbouw = [...this.state.horecaafbouw];
    let date = new Date(e.target.value + 'T00:00:00');
    let fbTimestamp = Timestamp.fromDate(date);

    horecaafbouw[e.target.dataset.id][e.target.classList[0]] = fbTimestamp;
    this.setState({ horecaafbouw });
  };

  horecaAfbouwTimeChange = (e) => {
    let horecaafbouw = [...this.state.horecaafbouw];
    horecaafbouw[e.target.dataset.id][e.target.classList[0]] = e.target.value;
    this.setState({ horecaafbouw });
  };

  removeLocMan = (param) => {
    this.setState((prevState) => {
      const state = { locatiemanager: [...prevState.locatiemanager] };
      if (state.locatiemanager.length > 1) {
        state.locatiemanager.splice(param, 1);
      } else {
        state.locatiemanager = null;
      }
      return state;
    });
  };

  removeCateraar = (param) => {
    this.setState((prevState) => {
      const state = { cateraar: [...prevState.cateraar] };
      if (state.cateraar.length > 1) {
        state.cateraar.splice(param, 1);
      } else {
        state.cateraar = null;
      }
      return state;
    });
  };

  removeShowDay = (param) => {
    this.setState((prevState) => {
      const state = { showtijden: [...prevState.showtijden] };
      if (state.showtijden.length > 1) {
        state.showtijden.splice(param, 1);
      } else {
        state.showtijden = null;
      }
      return state;
    });
  };

  removeHorecaShowDay = (param) => {
    this.setState((prevState) => {
      const state = { horecashow: [...prevState.horecashow] };
      if (state.horecashow.length > 1) {
        state.horecashow.splice(param, 1);
      } else {
        state.horecashow = null;
      }
      return state;
    });
  };

  removeHorecaAfbouwDay = (param) => {
    this.setState((prevState) => {
      const state = { horecaafbouw: [...prevState.horecaafbouw] };
      if (state.horecaafbouw.length > 1) {
        state.horecaafbouw.splice(param, 1);
      } else {
        state.horecaafbouw = null;
      }
      return state;
    });
  };

  removeHorecaOpbouwDay = (param) => {
    this.setState((prevState) => {
      const state = { horecaopbouw: [...prevState.horecaopbouw] };
      if (state.horecaopbouw.length > 1) {
        state.horecaopbouw.splice(param, 1);
      } else {
        state.horecaopbouw = null;
      }
      return state;
    });
  };

  handleImageUpload = (e) => {
    e.preventDefault();
    if (typeof e.target.files[0] !== 'undefined') {
      this.setState({
        [e.target.id]: e.target.files[0],
      });
    }
  };

  addShowDay = () => {
    if (this.state.showtijden == null) {
      this.setState(() => ({
        showtijden: [
          { datum: '', begintijd: '', eindtijd: '', estcapacity: '' },
        ],
      }));
    } else {
      this.setState((prevState) => ({
        showtijden: [
          ...prevState.showtijden,
          { datum: '', begintijd: '', eindtijd: '', estcapacity: '' },
        ],
      }));
    }
  };

  addHorecaOpbouwDay = () => {
    if (this.state.horecaopbouw == null) {
      this.setState(() => ({
        horecaopbouw: [
          { hopdatum: '', hopvanaf: '', hoptot: '', hopmedewerker: '' },
        ],
      }));
    } else {
      this.setState((prevState) => ({
        horecaopbouw: [
          ...prevState.horecaopbouw,
          { hopdatum: '', hopvanaf: '', hoptot: '', hopmedewerker: '' },
        ],
      }));
    }
  };

  addHorecaShowDay = () => {
    if (this.state.horecashow == null) {
      this.setState(() => ({
        horecashow: [{ hsdatum: '', hsvanaf: '', hstot: '', hsmedewerker: '' }],
      }));
    } else {
      this.setState((prevState) => ({
        horecashow: [
          ...prevState.horecashow,
          { hsdatum: '', hsvanaf: '', hstot: '', hsmedewerker: '' },
        ],
      }));
    }
  };

  addLocMan = () => {
    if (this.state.locatiemanager == null) {
      this.setState(() => ({
        locatiemanager: [{ locmannaam: '', locmantel: '', locmanmail: '' }],
      }));
    } else {
      this.setState((prevState) => ({
        locatiemanager: [
          ...prevState.locatiemanager,
          { locmannaam: '', locmantel: '', locmanmail: '' },
        ],
      }));
    }
  };

  addCateraar = () => {
    if (this.state.cateraar == null) {
      this.setState(() => ({
        cateraar: [{ cateraarnaam: '', cateraartel: '', cateraarmail: '' }],
      }));
    } else {
      this.setState((prevState) => ({
        cateraar: [
          ...prevState.cateraar,
          { cateraarnaam: '', cateraartel: '', cateraarmail: '' },
        ],
      }));
    }
  };

  addHorecaAfbouwDay = () => {
    if (this.state.horecaafbouw == null) {
      this.setState(() => ({
        horecaafbouw: [
          { hafdatum: '', hafvanaf: '', haftot: '', hafmedewerker: '' },
        ],
      }));
    } else {
      this.setState((prevState) => ({
        horecaafbouw: [
          ...prevState.horecaafbouw,
          { hafdatum: '', hafvanaf: '', haftot: '', hafmedewerker: '' },
        ],
      }));
    }
  };

  getFacturen = () => {
    firebase
      .firestore()
      .collection('facturen')
      .onSnapshot((snapshot) => {
        snapshot.docs.forEach((doc) => {
          this.setState({
            factuuren: [...this.state.factuuren, doc.data()],
          });
        });
      });
  };

  componentDidMount() {
    this.getFacturen();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.createEvenement(this.state, this.state.img);
    notify();
    setTimeout(() => {
      this.props.history.push('/');
    }, 3000);
  };

  render() {
    const { auth } = this.props;

    if (!auth.uid) return <Redirect to="/signin" />;

    return (
      <div className="flex-container">
        <div className="container-wide">
          <button className="btn-icon back-btn" onClick={this.handleBack}>
            <span>
              <ArrowLeft size={20} />
            </span>
            Back
          </button>
          <form onSubmit={this.handleSubmit} className="full-width">
            <div className="form-title">
              <div>
                <h5>TOEVOEGEN</h5>
                <h6>NIEUW EVENEMENT</h6>
              </div>
              <div>
                <button className="button" type="submit">
                  Evenement toevoegen
                </button>
                <Toaster />
              </div>
            </div>
            <div className="container-wide-split">
              <div className="split-1">
                <div className="input-field big">
                  <h6>ALGEMEEN</h6>
                </div>
                <div className="input-field small">
                  <label htmlFor="projectnaam">Projectnaam*</label>
                  <input
                    type="text"
                    id="projectnaam"
                    onChange={this.handleChange}
                    required
                  ></input>
                </div>
                <div className="input-field small">
                  <select
                    className="browser-default"
                    onChange={this.handleChange}
                    id="status"
                  >
                    <option value="" disabled selected>
                      Status*
                    </option>
                    <option value="Bevestigd">Bevestigd</option>
                    <option value="Concept">Concept</option>
                    <option value="Cancelled">Cancelled</option>
                    <option value="Feestdag">Feestdag</option>
                  </select>
                </div>
                <div className="input-field small">
                  <input
                    type="date"
                    id="startdatum"
                    onChange={this.dateChange}
                    required
                  ></input>
                </div>
                <div className="input-field small">
                  <input
                    type="date"
                    id="einddatum"
                    onChange={this.dateChange}
                    required
                  ></input>
                </div>
                <div className="input-field small">
                  <select
                    className="browser-default"
                    onChange={this.handleChange}
                    id="organisator"
                  >
                    <option value="" disabled selected>
                      Organisatie*
                    </option>
                    {this.state.factuuren !== null && (
                      <>
                        {this.state.factuuren.map((factuur) => {
                          return (
                            <option key={factuur.id} value={factuur.title}>
                              {factuur.title}
                            </option>
                          );
                        })}
                      </>
                    )}
                  </select>
                </div>
                <div className="input-field small">
                  <label htmlFor="partner">Partner</label>
                  <input
                    type="text"
                    id="partner"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field big">
                  <label htmlFor="draaiboeklink">Draaiboek link</label>
                  <input
                    type="text"
                    id="draaiboeklink"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field big">
                  <label htmlFor="adres">Adres</label>
                  <input
                    type="text"
                    id="adres"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="venue">Venue</label>
                  <input
                    type="text"
                    id="venue"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="capaciteit">Capaciteit</label>
                  <input
                    type="text"
                    id="capaciteit"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="plaats">Plaats</label>
                  <input
                    type="text"
                    id="plaats"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small"></div>
                <div className="input-field big">
                  <h6 className="label">Duurzaamheid</h6>
                  <div className="radio-group">
                    <p className="radio-btn">
                      <label>
                        <input
                          name="duurzaamheid"
                          type="radio"
                          value="NotGreen"
                          onChange={this.handleRadioChange}
                        />
                        <span>Not Green</span>
                      </label>
                    </p>
                    <p className="radio-btn">
                      <label>
                        <input
                          name="duurzaamheid"
                          type="radio"
                          value="GreenBasic"
                          onChange={this.handleRadioChange}
                        />
                        <span>GreenBasic</span>
                      </label>
                    </p>
                    <p className="radio-btn">
                      <label>
                        <input
                          name="duurzaamheid"
                          type="radio"
                          value="Green+"
                          onChange={this.handleRadioChange}
                        />
                        <span>Green+</span>
                      </label>
                    </p>
                    <p className="radio-btn">
                      <label>
                        <input
                          name="duurzaamheid"
                          type="radio"
                          value="Green++"
                          onChange={this.handleRadioChange}
                        />
                        <span>Green++</span>
                      </label>
                    </p>
                  </div>
                </div>
                <div className="input-field big">
                  <label htmlFor="opmerkingen">Opmerkingen</label>
                  <textarea
                    id="opmerkingen"
                    className="materialize-textarea"
                    onChange={this.handleChange}
                  ></textarea>
                </div>
                <div className="input-field upload big">
                  <label htmlFor="image">Afbeelding uploaden:</label>
                  <input
                    type="file"
                    name="image"
                    id="img"
                    onChange={this.handleImageUpload}
                  />
                </div>
                <div className="input-field big">
                  <h6>MANAGERS</h6>
                </div>
                <div className="input-field small">
                  <label htmlFor="projecteigenaar">Project eigenaar</label>
                  <input
                    type="text"
                    id="projecteigenaar"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="projectmanager">Project manager</label>
                  <input
                    type="text"
                    id="projectmanager"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="marketingmanager">Marketing manager</label>
                  <input
                    type="text"
                    id="marketingmanager"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="productiemanager">Productie manager</label>
                  <input
                    type="text"
                    id="productiemanager"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="horecamanager">Horeca manager</label>
                  <input
                    type="text"
                    id="horecamanager"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="programmeur">Programmeur</label>
                  <input
                    type="text"
                    id="programmeur"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="financemanager">Finance manager</label>
                  <input
                    type="text"
                    id="financemanager"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="financeticketingmanager">
                    Finance &#38; ticketing manager
                  </label>
                  <input
                    type="text"
                    id="financeticketingmanager"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="administratie">Administratie</label>
                  <input
                    type="text"
                    id="administratie"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="eventmanager">Event manager</label>
                  <input
                    type="text"
                    id="eventmanager"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="leveranciercrew">Leverancier crew show</label>
                  <input
                    type="text"
                    id="leveranciercrew"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small"></div>
                <div className="input-field big">
                  <h6>PRODUCTIE</h6>
                </div>
                <div className="input-field big">
                  <h6 className="label">Transport</h6>
                  <div className="radio-group">
                    <p className="radio-btn">
                      <label>
                        <input
                          name="transport"
                          type="radio"
                          value="Intern transport"
                          onChange={this.handleRadioChange}
                        />
                        <span>Intern transport</span>
                      </label>
                    </p>
                    <p className="radio-btn">
                      <label>
                        <input
                          name="transport"
                          type="radio"
                          value="Extern transport"
                          onChange={this.handleRadioChange}
                        />
                        <span>Extern transport</span>
                      </label>
                    </p>
                    <p className="radio-btn">
                      <label>
                        <input
                          name="transport"
                          type="radio"
                          value="Geen transport"
                          onChange={this.handleRadioChange}
                        />
                        <span>Geen transport</span>
                      </label>
                    </p>
                  </div>
                </div>
                <div className="input-field big">
                  <h6 className="label">Tourset</h6>
                  <div className="radio-group">
                    <p className="radio-btn">
                      <label>
                        <input
                          name="tourset"
                          type="radio"
                          value="Tourset klein"
                          onChange={this.handleRadioChange}
                        />
                        <span>Tourset klein</span>
                      </label>
                    </p>
                    <p className="radio-btn">
                      <label>
                        <input
                          name="tourset"
                          type="radio"
                          value="Tourset groot met extra ground support"
                          onChange={this.handleRadioChange}
                        />
                        <span>Tourset groot met extra ground support</span>
                      </label>
                    </p>
                    <p className="radio-btn">
                      <label>
                        <input
                          name="tourset"
                          type="radio"
                          value="Aangepaste set klein"
                          onChange={this.handleRadioChange}
                        />
                        <span>Aangepaste set klein</span>
                      </label>
                    </p>
                    <p className="radio-btn">
                      <label>
                        <input
                          name="tourset"
                          type="radio"
                          value="Aangepaste set groot"
                          onChange={this.handleRadioChange}
                        />
                        <span>Aangepaste set groot</span>
                      </label>
                    </p>
                    <p className="radio-btn">
                      <label>
                        <input
                          name="tourset"
                          type="radio"
                          value="Big Party (layer podium)"
                          onChange={this.handleRadioChange}
                        />
                        <span>Big Party (layer podium)</span>
                      </label>
                    </p>
                  </div>
                </div>
                <div className="input-field small">
                  <label htmlFor="decorproducent">
                    Decor / Technisch producent
                  </label>
                  <input
                    type="text"
                    id="decorproducent"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small"></div>
              </div>
              <div className="split-2">
                <div className="input-field big">
                  <h6>LOCATIE</h6>
                </div>
                <div className="input-field big">
                  <h6 className="label">CONTACTPERSOON LOCATIE</h6>
                  <table className="striped">
                    <thead>
                      <tr>
                        <th>Naam</th>
                        <th>Telefoonnummer</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    {this.state.locatiemanager != null && (
                      <tbody>
                        {this.state.locatiemanager.map((val, idx) => {
                          return (
                            <tr key={idx}>
                              <td>
                                <input
                                  type="text"
                                  id={'n-' + idx}
                                  data-id={idx}
                                  className="locmannaam"
                                  onChange={this.locManChange}
                                  required
                                ></input>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  id={'t-' + idx}
                                  data-id={idx}
                                  className="locmantel"
                                  onChange={this.locManChange}
                                  required
                                ></input>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  id={'m-' + idx}
                                  data-id={idx}
                                  className="locmanmail"
                                  onChange={this.locManChange}
                                  required
                                ></input>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  onClick={() => this.removeLocMan(idx)}
                                >
                                  X
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                  <button
                    className="event-add"
                    type="button"
                    onClick={this.addLocMan}
                  >
                    <p>Contactpersoon toevoegen</p>
                    <span>
                      <Plus size={20} />
                    </span>
                  </button>
                </div>
                <div className="input-field small"></div>
                <div className="input-field big">
                  <h6>VEILIGHEID & VERGUNNINGEN</h6>
                </div>
                <div className="input-field small">
                  <label htmlFor="wetgeving">Wetgeving</label>
                  <input
                    type="text"
                    id="wetgeving"
                    onChange={this.handleChange}
                  ></input>
                  <span
                    class="helper-text"
                    data-error="wrong"
                    data-success="right"
                  >
                    Vergunning/ meldingsplicht / ontheffing drank
                  </span>
                </div>
                <div className="input-field small">
                  <label htmlFor="beveiliging">Beveiligingspartij</label>
                  <input
                    type="text"
                    id="beveiliging"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="medisch">Medische dienst</label>
                  <input
                    type="text"
                    id="medisch"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small"></div>
                <div className="input-field big">
                  <h6>HORECA</h6>
                </div>
                <div className="input-field small">
                  <label htmlFor="horecamanagershow">
                    Horeca manager (show)
                  </label>
                  <input
                    type="text"
                    id="horecamanagershow"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="barleverancier">
                    Leverancier barpersoneel
                  </label>
                  <input
                    type="text"
                    id="barleverancier"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="brouwerij">Brouwerij</label>
                  <input
                    type="text"
                    id="brouwerij"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="restleverancier">
                    Leverancier restpakket
                  </label>
                  <input
                    type="text"
                    id="restleverancier"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="beverageassortiment">
                    Beverage - Assortiment
                  </label>
                  <input
                    type="text"
                    id="beverageassortiment"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="beverageactivaties">
                    Beverage - Activaties
                  </label>
                  <input
                    type="text"
                    id="beverageactivaties"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="beveragespecialsbier">
                    Beverage - Specials bier op tap
                  </label>
                  <input
                    type="text"
                    id="beveragespecialsbier"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="beverageshirt">Beverage - T-shirts</label>
                  <input
                    type="text"
                    id="beverageshirt"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="muntprijs">Muntprijs</label>
                  <input
                    type="text"
                    id="muntprijs"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small"></div>
                <div className="input-field big">
                  <h6 className="label">CATERAARS</h6>
                  <table className="striped">
                    <thead>
                      <tr>
                        <th>Naam</th>
                        <th>Telefoonnummer</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    {this.state.cateraar != null && (
                      <tbody>
                        {this.state.cateraar.map((val, idx) => {
                          return (
                            <tr key={idx}>
                              <td>
                                <input
                                  type="text"
                                  id={'n-' + idx}
                                  data-id={idx}
                                  className="cateraarnaam"
                                  onChange={this.cateraarChange}
                                  required
                                ></input>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  id={'t-' + idx}
                                  data-id={idx}
                                  className="cateraartel"
                                  onChange={this.cateraarChange}
                                  required
                                ></input>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  id={'m-' + idx}
                                  data-id={idx}
                                  className="cateraarmail"
                                  onChange={this.cateraarChange}
                                  required
                                ></input>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  onClick={() => this.removeCateraar(idx)}
                                >
                                  X
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                  <button
                    className="event-add"
                    type="button"
                    onClick={this.addCateraar}
                  >
                    <p>Cateraar toevoegen</p>
                    <span>
                      <Plus size={20} />
                    </span>
                  </button>
                </div>
                <div className="input-field big">
                  <h6 className="label">HORECA OPBOUW</h6>
                  <table className="striped">
                    <thead>
                      <tr>
                        <th>Opbouwdag</th>
                        <th>Vanaf</th>
                        <th>Tot</th>
                        <th>Medewerkers</th>
                      </tr>
                    </thead>
                    {this.state.horecaopbouw != null && (
                      <tbody>
                        {this.state.horecaopbouw.map((val, idx) => {
                          return (
                            <tr key={idx}>
                              <td>
                                <input
                                  type="date"
                                  id={'d-' + idx}
                                  data-id={idx}
                                  className="hopdatum"
                                  onChange={this.horecaOpbouwDateChange}
                                  required
                                ></input>
                              </td>
                              <td>
                                <input
                                  type="time"
                                  id={'v-' + idx}
                                  data-id={idx}
                                  className="hopvanaf"
                                  onChange={this.horecaOpbouwTimeChange}
                                  required
                                ></input>
                              </td>
                              <td>
                                <input
                                  type="time"
                                  id={'t-' + idx}
                                  data-id={idx}
                                  className="hoptot"
                                  onChange={this.horecaOpbouwTimeChange}
                                  required
                                ></input>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  id={'m-' + idx}
                                  data-id={idx}
                                  className="hopmedewerker"
                                  onChange={this.horecaOpbouwTimeChange}
                                  required
                                ></input>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  onClick={() =>
                                    this.removeHorecaOpbouwDay(idx)
                                  }
                                >
                                  X
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                  <button
                    className="event-add"
                    type="button"
                    onClick={this.addHorecaOpbouwDay}
                  >
                    <p>Opbouwdag toevoegen</p>
                    <span>
                      <Plus size={20} />
                    </span>
                  </button>
                </div>
                <div className="input-field big">
                  <h6 className="label">HORECA SHOW</h6>
                  <table className="striped">
                    <thead>
                      <tr>
                        <th>Showdag</th>
                        <th>Vanaf</th>
                        <th>Tot</th>
                        <th>Medewerkers</th>
                      </tr>
                    </thead>
                    {this.state.horecashow != null && (
                      <tbody>
                        {this.state.horecashow.map((val, idx) => {
                          return (
                            <tr key={idx}>
                              <td>
                                <input
                                  type="date"
                                  id={'d-' + idx}
                                  data-id={idx}
                                  className="hsdatum"
                                  onChange={this.horecaShowDateChange}
                                  required
                                ></input>
                              </td>
                              <td>
                                <input
                                  type="time"
                                  id={'v-' + idx}
                                  data-id={idx}
                                  className="hsvanaf"
                                  onChange={this.horecaShowTimeChange}
                                  required
                                ></input>
                              </td>
                              <td>
                                <input
                                  type="time"
                                  id={'t-' + idx}
                                  data-id={idx}
                                  className="hstot"
                                  onChange={this.horecaShowTimeChange}
                                  required
                                ></input>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  id={'m-' + idx}
                                  data-id={idx}
                                  className="hsmedewerker"
                                  onChange={this.horecaShowTimeChange}
                                  required
                                ></input>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  onClick={() => this.removeHorecaShowDay(idx)}
                                >
                                  X
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                  <button
                    className="event-add"
                    type="button"
                    onClick={this.addHorecaShowDay}
                  >
                    <p>Showdag toevoegen</p>
                    <span>
                      <Plus size={20} />
                    </span>
                  </button>
                </div>
                <div className="input-field big">
                  <h6 className="label">HORECA AFBOUW</h6>
                  <table className="striped">
                    <thead>
                      <tr>
                        <th>Afbouwdag</th>
                        <th>Vanaf</th>
                        <th>Tot</th>
                        <th>Medewerkers</th>
                      </tr>
                    </thead>
                    {this.state.horecaafbouw != null && (
                      <tbody>
                        {this.state.horecaafbouw.map((val, idx) => {
                          return (
                            <tr key={idx}>
                              <td>
                                <input
                                  type="date"
                                  id={'d-' + idx}
                                  data-id={idx}
                                  className="hafdatum"
                                  onChange={this.horecaAfbouwDateChange}
                                  required
                                ></input>
                              </td>
                              <td>
                                <input
                                  type="time"
                                  id={'v-' + idx}
                                  data-id={idx}
                                  className="hafvanaf"
                                  onChange={this.horecaAfbouwTimeChange}
                                  required
                                ></input>
                              </td>
                              <td>
                                <input
                                  type="time"
                                  id={'t-' + idx}
                                  data-id={idx}
                                  className="haftot"
                                  onChange={this.horecaAfbouwTimeChange}
                                  required
                                ></input>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  id={'m-' + idx}
                                  data-id={idx}
                                  className="hafmedewerker"
                                  onChange={this.horecaAfbouwTimeChange}
                                  required
                                ></input>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  onClick={() =>
                                    this.removeHorecaAfbouwDay(idx)
                                  }
                                >
                                  X
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                  <button
                    className="event-add"
                    type="button"
                    onClick={this.addHorecaAfbouwDay}
                  >
                    <p>Afbouwdag toevoegen</p>
                    <span>
                      <Plus size={20} />
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="input-field extra-wide">
              <h4 className="form-title">SHOW</h4>
              <table className="striped">
                <thead>
                  <tr>
                    <th>Showdagen</th>
                    <th>Start showtijd</th>
                    <th>Einde showtijd</th>
                    <th>Verwachte aantal bezoekers</th>
                  </tr>
                </thead>
                {this.state.showtijden != null && (
                  <tbody>
                    {this.state.showtijden.map((val, idx) => {
                      return (
                        <tr key={idx}>
                          <td>
                            <input
                              type="date"
                              id={'day-' + idx}
                              data-id={idx}
                              className="datum"
                              onChange={this.showDateChange}
                              required
                            ></input>
                          </td>
                          <td>
                            <input
                              type="time"
                              id={'bg-' + idx}
                              data-id={idx}
                              className="begintijd"
                              onChange={this.showTimeChange}
                              required
                            ></input>
                          </td>
                          <td>
                            <input
                              type="time"
                              id={'e-' + idx}
                              data-id={idx}
                              className="eindtijd"
                              onChange={this.showTimeChange}
                              required
                            ></input>
                          </td>
                          <td>
                            <input
                              type="text"
                              id={'e-' + idx}
                              data-id={idx}
                              className="estcapacity"
                              onChange={this.showTimeChange}
                              required
                            ></input>
                          </td>
                          <td>
                            <button
                              type="button"
                              onClick={() => this.removeShowDay(idx)}
                            >
                              X
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
              <button
                className="event-add"
                type="button"
                onClick={this.addShowDay}
              >
                <p>Showdag toevoegen</p>
                <span>
                  <Plus size={20} />
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    facturen: state.firestore.ordered.facturen,
  };
};

const mapDistpatchToProps = (dispatch) => {
  return {
    createEvenement: (evenement, img) =>
      dispatch(createEvenement(evenement, img)),
  };
};

export default connect(mapStateToProps, mapDistpatchToProps)(CreateEvenement);
