import React, { Component } from 'react';
import { connect } from 'react-redux';
import { editEvenement } from '../../store/actions/evenementActions.js';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import toast, { Toaster } from 'react-hot-toast';
import { firestoreConnect } from 'react-redux-firebase';
import { Plus, ArrowLeft } from 'react-feather';
import { Timestamp } from 'firebase/firestore';

import moment from 'moment';
import 'moment/locale/nl';

moment.locale('nl');

const notify = () =>
  toast.success('Evenement is bijgewerkt, je wordt automatisch doorgestuurd.');

class EditEvenement extends Component {
  state = {
    id: this.props.match.params.id,
    projectnaam: this.props.evenement.projectnaam
      ? this.props.evenement.projectnaam
      : '',
    draaiboeklink: this.props.evenement.draaiboeklink
      ? this.props.evenement.draaiboeklink
      : '',
    startdatum: this.props.evenement.startdatum
      ? this.props.evenement.startdatum
      : null,
    einddatum: this.props.evenement.einddatum
      ? this.props.evenement.einddatum
      : null,
    organisator: this.props.evenement.organisator
      ? this.props.evenement.organisator
      : null,
    partner: this.props.evenement.partner
      ? this.props.evenement.partner
      : 'NVT',
    plaats: this.props.evenement.plaats ? this.props.evenement.plaats : 'NVT',
    venue: this.props.evenement.venue ? this.props.evenement.venue : null,
    capaciteit: this.props.evenement.capaciteit
      ? this.props.evenement.capaciteit
      : null,
    adres: this.props.evenement.adres ? this.props.evenement.adres : null,
    status: this.props.evenement.status ? this.props.evenement.status : null,
    projecteigenaar: this.props.evenement.projecteigenaar
      ? this.props.evenement.projecteigenaar
      : null,
    projectmanager: this.props.evenement.projectmanager
      ? this.props.evenement.projectmanager
      : null,
    marketingmanager: this.props.evenement.marketingmanager
      ? this.props.evenement.marketingmanager
      : null,
    productiemanager: this.props.evenement.productiemanager
      ? this.props.evenement.productiemanager
      : null,
    horecamanager: this.props.evenement.horecamanager
      ? this.props.evenement.horecamanager
      : null,
    programmeur: this.props.evenement.programmeur
      ? this.props.evenement.programmeur
      : null,
    financemanager: this.props.evenement.financemanager
      ? this.props.evenement.financemanager
      : null,
    financeticketingmanager: this.props.evenement.financeticketingmanager
      ? this.props.evenement.financeticketingmanager
      : null,
    leveranciercrew: this.props.evenement.leveranciercrew
      ? this.props.evenement.leveranciercrew
      : null,
    administratie: this.props.evenement.administratie
      ? this.props.evenement.administratie
      : null,
    eventmanager: this.props.evenement.eventmanager
      ? this.props.evenement.eventmanager
      : null,
    opmerkingen: this.props.evenement.opmerkingen
      ? this.props.evenement.opmerkingen
      : null,
    showtijden: this.props.evenement.showtijden,
    horecamanagershow: this.props.evenement.horecamanagershow
      ? this.props.evenement.horecamanagershow
      : null,
    barleverancier: this.props.evenement.barleverancier
      ? this.props.evenement.barleverancier
      : null,
    brouwerij: this.props.evenement.brouwerij
      ? this.props.evenement.brouwerij
      : null,
    restleverancier: this.props.evenement.restleverancier
      ? this.props.evenement.restleverancier
      : null,
    beveiliging: this.props.evenement.beveiliging
      ? this.props.evenement.beveiliging
      : null,
    medisch: this.props.evenement.medisch ? this.props.evenement.medisch : null,
    wetgeving: this.props.evenement.wetgeving
      ? this.props.evenement.wetgeving
      : null,
    locatiemanager: this.props.evenement.locatiemanager
      ? this.props.evenement.locatiemanager
      : null,
    transport: this.props.evenement.transport
      ? this.props.evenement.transport
      : null,
    tourset: this.props.evenement.tourset ? this.props.evenement.tourset : null,
    beverageassortiment: this.props.evenement.beverageassortiment
      ? this.props.evenement.beverageassortiment
      : null,
    beverageactivaties: this.props.evenement.beverageactivaties
      ? this.props.evenement.beverageactivaties
      : null,
    beveragespecialsbier: this.props.evenement.beveragespecialsbier
      ? this.props.evenement.beveragespecialsbier
      : null,
    beverageshirt: this.props.evenement.beverageshirt
      ? this.props.evenement.beverageshirt
      : null,
    muntprijs: this.props.evenement.muntprijs
      ? this.props.evenement.muntprijs
      : null,
    horecaopbouw: this.props.evenement.horecaopbouw
      ? this.props.evenement.horecaopbouw
      : null,
    horecashow: this.props.evenement.horecashow
      ? this.props.evenement.horecashow
      : null,
    horecaafbouw: this.props.evenement.horecaafbouw
      ? this.props.evenement.horecaafbouw
      : null,
    cateraar: this.props.evenement.cateraar
      ? this.props.evenement.cateraar
      : null,
    decorproducent: this.props.evenement.decorproducent
      ? this.props.evenement.decorproducent
      : null,
    duurzaamheid: this.props.evenement.duurzaamheid
      ? this.props.evenement.duurzaamheid
      : null,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleRadioChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleBack = (e) => {
    e.preventDefault();
    window.history.back();
  };

  dateChange = (e) => {
    let date = new Date(e.target.value + 'T00:00:00');
    let fbTimestamp = Timestamp.fromDate(date);
    this.setState({
      [e.target.id]: fbTimestamp,
    });
  };

  showDateChange = (e) => {
    let showtijden = [...this.state.showtijden];
    let date = new Date(e.target.value + 'T00:00:00');
    let fbTimestamp = Timestamp.fromDate(date);

    showtijden[e.target.dataset.id] = {
      datum: fbTimestamp,
      begintijd: this.state.showtijden[e.target.dataset.id].begintijd,
      eindtijd: this.state.showtijden[e.target.dataset.id].eindtijd,
      estcapacity: this.state.showtijden[e.target.dataset.id].estcapacity,
    };
    this.setState({ showtijden });
  };

  showBeginTimeChange = (e) => {
    let showtijden = [...this.state.showtijden];
    showtijden[e.target.dataset.id] = {
      datum: this.state.showtijden[e.target.dataset.id].datum,
      begintijd: e.target.value,
      eindtijd: this.state.showtijden[e.target.dataset.id].eindtijd,
      estcapacity: this.state.showtijden[e.target.dataset.id].estcapacity,
    };
    this.setState({ showtijden });
  };

  showEndTimeChange = (e) => {
    let showtijden = [...this.state.showtijden];
    showtijden[e.target.dataset.id] = {
      datum: this.state.showtijden[e.target.dataset.id].datum,
      begintijd: this.state.showtijden[e.target.dataset.id].begintijd,
      eindtijd: e.target.value,
      estcapacity: this.state.showtijden[e.target.dataset.id].estcapacity,
    };
    this.setState({ showtijden });
  };

  showCapacityChange = (e) => {
    let showtijden = [...this.state.showtijden];
    showtijden[e.target.dataset.id] = {
      datum: this.state.showtijden[e.target.dataset.id].datum,
      begintijd: this.state.showtijden[e.target.dataset.id].begintijd,
      eindtijd: this.state.showtijden[e.target.dataset.id].eindtijd,
      estcapacity: e.target.value,
    };
    this.setState({ showtijden });
  };

  locManNameChange = (e) => {
    let locatiemanager = [...this.state.locatiemanager];
    locatiemanager[e.target.dataset.id] = {
      locmannaam: e.target.value,
      locmantel: this.state.locatiemanager[e.target.dataset.id].locmantel,
      locmanmail: this.state.locatiemanager[e.target.dataset.id].locmanmail,
    };
    this.setState({ locatiemanager });
  };

  cateraarNameChange = (e) => {
    let cateraar = [...this.state.cateraar];
    cateraar[e.target.dataset.id] = {
      cateraarnaam: e.target.value,
      cateraartel: this.state.cateraar[e.target.dataset.id].cateraartel,
      cateraarmail: this.state.cateraar[e.target.dataset.id].cateraarmail,
    };
    this.setState({ cateraar });
  };

  cateraarTelChange = (e) => {
    let cateraar = [...this.state.cateraar];
    cateraar[e.target.dataset.id] = {
      cateraarnaam: this.state.cateraar[e.target.dataset.id].cateraarnaam,
      cateraartel: e.target.value,
      cateraarmail: this.state.cateraar[e.target.dataset.id].cateraarmail,
    };
    this.setState({ cateraar });
  };

  cateraarMailChange = (e) => {
    let cateraar = [...this.state.cateraar];
    cateraar[e.target.dataset.id] = {
      cateraarnaam: this.state.cateraar[e.target.dataset.id].cateraarnaam,
      cateraartel: this.state.cateraar[e.target.dataset.id].cateraartel,
      cateraarmail: e.target.value,
    };
    this.setState({ cateraar });
  };

  locManTelChange = (e) => {
    let locatiemanager = [...this.state.locatiemanager];
    locatiemanager[e.target.dataset.id] = {
      locmannaam: this.state.locatiemanager[e.target.dataset.id].locmannaam,
      locmantel: e.target.value,
      locmanmail: this.state.locatiemanager[e.target.dataset.id].locmanmail,
    };
    this.setState({ locatiemanager });
  };

  locManMailChange = (e) => {
    let locatiemanager = [...this.state.locatiemanager];
    locatiemanager[e.target.dataset.id] = {
      locmannaam: this.state.locatiemanager[e.target.dataset.id].locmannaam,
      locmantel: this.state.locatiemanager[e.target.dataset.id].locmantel,
      locmanmail: e.target.value,
    };
    this.setState({ locatiemanager });
  };

  horecaOpbouwDateChange = (e) => {
    let horecaopbouw = [...this.state.horecaopbouw];
    let date = new Date(e.target.value + 'T00:00:00');
    let fbTimestamp = Timestamp.fromDate(date);

    horecaopbouw[e.target.dataset.id] = {
      hopdatum: fbTimestamp,
      hopvanaf: this.state.horecaopbouw[e.target.dataset.id].hopvanaf,
      hoptot: this.state.horecaopbouw[e.target.dataset.id].hoptot,
      hopmedewerker: this.state.horecaopbouw[e.target.dataset.id].hopmedewerker,
    };
    this.setState({ horecaopbouw });
  };

  horecaOpbouwVanafTimeChange = (e) => {
    let horecaopbouw = [...this.state.horecaopbouw];
    horecaopbouw[e.target.dataset.id] = {
      hopdatum: this.state.horecaopbouw[e.target.dataset.id].hopdatum,
      hopvanaf: e.target.value,
      hoptot: this.state.horecaopbouw[e.target.dataset.id].hoptot,
      hopmedewerker: this.state.horecaopbouw[e.target.dataset.id].hopmedewerker,
    };
    this.setState({ horecaopbouw });
  };

  horecaOpbouwTotTimeChange = (e) => {
    let horecaopbouw = [...this.state.horecaopbouw];
    horecaopbouw[e.target.dataset.id] = {
      hopdatum: this.state.horecaopbouw[e.target.dataset.id].hopdatum,
      hopvanaf: this.state.horecaopbouw[e.target.dataset.id].hopvanaf,
      hoptot: e.target.value,
      hopmedewerker: this.state.horecaopbouw[e.target.dataset.id].hopmedewerker,
    };
    this.setState({ horecaopbouw });
  };

  horecaOpbouwMedewerkerChange = (e) => {
    let horecaopbouw = [...this.state.horecaopbouw];
    horecaopbouw[e.target.dataset.id] = {
      hopdatum: this.state.horecaopbouw[e.target.dataset.id].hopdatum,
      hopvanaf: this.state.horecaopbouw[e.target.dataset.id].hopvanaf,
      hoptot: this.state.horecaopbouw[e.target.dataset.id].hoptot,
      hopmedewerker: e.target.value,
    };
    this.setState({ horecaopbouw });
  };

  horecaShowDateChange = (e) => {
    let horecashow = [...this.state.horecashow];
    let date = new Date(e.target.value + 'T00:00:00');
    let fbTimestamp = Timestamp.fromDate(date);

    horecashow[e.target.dataset.id] = {
      hsdatum: fbTimestamp,
      hsvanaf: this.state.horecashow[e.target.dataset.id].hsvanaf,
      hstot: this.state.horecashow[e.target.dataset.id].hstot,
      hsmedewerker: this.state.horecashow[e.target.dataset.id].hsmedewerker,
    };
    this.setState({ horecashow });
  };

  horecaShowVanafTimeChange = (e) => {
    let horecashow = [...this.state.horecashow];
    horecashow[e.target.dataset.id] = {
      hsdatum: this.state.horecashow[e.target.dataset.id].hsdatum,
      hsvanaf: e.target.value,
      hstot: this.state.horecashow[e.target.dataset.id].hstot,
      hsmedewerker: this.state.horecashow[e.target.dataset.id].hsmedewerker,
    };
    this.setState({ horecashow });
  };

  horecaShowTotTimeChange = (e) => {
    let horecashow = [...this.state.horecashow];
    horecashow[e.target.dataset.id] = {
      hsdatum: this.state.horecashow[e.target.dataset.id].hsdatum,
      hsvanaf: this.state.horecashow[e.target.dataset.id].hsvanaf,
      hstot: e.target.value,
      hsmedewerker: this.state.horecashow[e.target.dataset.id].hsmedewerker,
    };
    this.setState({ horecashow });
  };

  horecaShowMedewerkerChange = (e) => {
    let horecashow = [...this.state.horecashow];
    horecashow[e.target.dataset.id] = {
      hsdatum: this.state.horecashow[e.target.dataset.id].hsdatum,
      hsvanaf: this.state.horecashow[e.target.dataset.id].hsvanaf,
      hstot: this.state.horecashow[e.target.dataset.id].hstot,
      hsmedewerker: e.target.value,
    };
    this.setState({ horecashow });
  };

  horecaAfbouwDateChange = (e) => {
    let horecaafbouw = [...this.state.horecaafbouw];
    let date = new Date(e.target.value + 'T00:00:00');
    let fbTimestamp = Timestamp.fromDate(date);

    horecaafbouw[e.target.dataset.id] = {
      hafdatum: fbTimestamp,
      hafvanaf: this.state.horecaafbouw[e.target.dataset.id].hafvanaf,
      haftot: this.state.horecaafbouw[e.target.dataset.id].haftot,
      hafmedewerker: this.state.horecaafbouw[e.target.dataset.id].hafmedewerker,
    };
    this.setState({ horecaafbouw });
  };

  horecaAfbouwVanafTimeChange = (e) => {
    let horecaafbouw = [...this.state.horecaafbouw];
    horecaafbouw[e.target.dataset.id] = {
      hafdatum: this.state.horecaafbouw[e.target.dataset.id].hafdatum,
      hafvanaf: e.target.value,
      haftot: this.state.horecaafbouw[e.target.dataset.id].haftot,
      hafmedewerker: this.state.horecaafbouw[e.target.dataset.id].hafmedewerker,
    };
    this.setState({ horecaafbouw });
  };

  horecaAfbouwTotTimeChange = (e) => {
    let horecaafbouw = [...this.state.horecaafbouw];
    horecaafbouw[e.target.dataset.id] = {
      hafdatum: this.state.horecaafbouw[e.target.dataset.id].hafdatum,
      hafvanaf: this.state.horecaafbouw[e.target.dataset.id].hafvanaf,
      haftot: e.target.value,
      hafmedewerker: this.state.horecaafbouw[e.target.dataset.id].hafmedewerker,
    };
    this.setState({ horecaafbouw });
  };

  horecaAfbouwMedewerkerChange = (e) => {
    let horecaafbouw = [...this.state.horecaafbouw];
    horecaafbouw[e.target.dataset.id] = {
      hafdatum: this.state.horecaafbouw[e.target.dataset.id].hafdatum,
      hafvanaf: this.state.horecaafbouw[e.target.dataset.id].hafvanaf,
      haftot: this.state.horecaafbouw[e.target.dataset.id].haftot,
      hafmedewerker: e.target.value,
    };
    this.setState({ horecaafbouw });
  };

  addShowDay = () => {
    if (this.state.showtijden == null) {
      this.setState(() => ({
        showtijden: [
          {
            datum: moment(),
            begintijd: '00:00',
            eindtijd: '00:00',
            estcapacity: '',
          },
        ],
      }));
    } else {
      this.setState((prevState) => ({
        showtijden: [
          ...prevState.showtijden,
          {
            datum: moment(),
            begintijd: '00:00',
            eindtijd: '00:00',
            estcapacity: '',
          },
        ],
      }));
    }
  };

  addLocMan = () => {
    if (this.state.locatiemanager == null) {
      this.setState(() => ({
        locatiemanager: [
          {
            locmannaam: '',
            locmantel: '',
            locmanmail: '',
          },
        ],
      }));
    } else {
      this.setState((prevState) => ({
        locatiemanager: [
          ...prevState.locatiemanager,
          {
            locmannaam: '',
            locmantel: '',
            locmanmail: '',
          },
        ],
      }));
    }
  };

  addCateraar = () => {
    if (this.state.cateraar == null) {
      this.setState(() => ({
        cateraar: [
          {
            cateraarnaam: '',
            cateraartel: '',
            cateraarmail: '',
          },
        ],
      }));
    } else {
      this.setState((prevState) => ({
        cateraar: [
          ...prevState.cateraar,
          {
            cateraarnaam: '',
            cateraartel: '',
            cateraarmail: '',
          },
        ],
      }));
    }
  };

  addHorecaOpbouwDay = () => {
    if (this.state.horecaopbouw == null) {
      this.setState(() => ({
        horecaopbouw: [
          {
            hopdatum: moment(),
            hopvanaf: '00:00',
            hoptot: '00:00',
            hopmedewerker: '',
          },
        ],
      }));
    } else {
      this.setState((prevState) => ({
        horecaopbouw: [
          ...prevState.horecaopbouw,
          {
            hopdatum: moment(),
            hopvanaf: '00:00',
            hoptot: '00:00',
            hopmedewerker: '',
          },
        ],
      }));
    }
  };

  addHorecaShowDay = () => {
    if (this.state.horecashow == null) {
      this.setState(() => ({
        horecashow: [
          {
            hsdatum: moment(),
            hsvanaf: '00:00',
            hstot: '00:00',
            hsmedewerker: '',
          },
        ],
      }));
    } else {
      this.setState((prevState) => ({
        horecashow: [
          ...prevState.horecashow,
          {
            hsdatum: moment(),
            hsvanaf: '00:00',
            hstot: '00:00',
            hsmedewerker: '',
          },
        ],
      }));
    }
  };

  addHorecaAfbouwDay = () => {
    if (this.state.horecaafbouw == null) {
      this.setState(() => ({
        horecaafbouw: [
          {
            hafdatum: moment(),
            hafvanaf: '00:00',
            haftot: '00:00',
            hafmedewerker: '',
          },
        ],
      }));
    } else {
      this.setState((prevState) => ({
        horecaafbouw: [
          ...prevState.horecaafbouw,
          {
            hafdatum: moment(),
            hafvanaf: '00:00',
            haftot: '00:00',
            hafmedewerker: '',
          },
        ],
      }));
    }
  };

  removeLocMan = (param) => {
    this.setState((prevState) => {
      const state = { locatiemanager: [...prevState.locatiemanager] };
      if (state.locatiemanager.length > 1) {
        state.locatiemanager.splice(param, 1);
      } else {
        state.locatiemanager = null;
      }
      return state;
    });
  };

  removeCateraar = (param) => {
    this.setState((prevState) => {
      const state = { cateraar: [...prevState.cateraar] };
      if (state.cateraar.length > 1) {
        state.cateraar.splice(param, 1);
      } else {
        state.cateraar = null;
      }
      return state;
    });
  };

  removeShowDay = (param) => {
    this.setState((prevState) => {
      const state = { showtijden: [...prevState.showtijden] };
      if (state.showtijden.length > 1) {
        state.showtijden.splice(param, 1);
      } else {
        state.showtijden = null;
      }
      return state;
    });
  };

  removeHorecaAfbouwDay = (param) => {
    this.setState((prevState) => {
      const state = { horecaafbouw: [...prevState.horecaafbouw] };
      if (state.horecaafbouw.length > 1) {
        state.horecaafbouw.splice(param, 1);
      } else {
        state.horecaafbouw = null;
      }
      return state;
    });
  };

  removeHorecaShowDay = (param) => {
    this.setState((prevState) => {
      const state = { horecashow: [...prevState.horecashow] };
      if (state.horecashow.length > 1) {
        state.horecashow.splice(param, 1);
      } else {
        state.horecashow = null;
      }
      return state;
    });
  };

  removeHorecaOpbouwDay = (param) => {
    this.setState((prevState) => {
      const state = { horecaopbouw: [...prevState.horecaopbouw] };
      if (state.horecaopbouw.length > 1) {
        state.horecaopbouw.splice(param, 1);
      } else {
        state.horecaopbouw = null;
      }
      return state;
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.editEvenement(this.state, this.state.id);
    notify();
    setTimeout(() => {
      this.props.history.push('/evenement/' + this.state.id);
    }, 3000);
  };
  render() {
    const { auth, evenement, facturen } = this.props;
    if (!auth.uid) return <Redirect to="/signin" />;

    return (
      <div className="flex-container">
        <div className="container-wide">
          <button className="btn-icon back-btn" onClick={this.handleBack}>
            <span>
              <ArrowLeft size={20} />
            </span>
            Back
          </button>
          <form onSubmit={this.handleSubmit} className="full-width">
            <div className="form-title">
              <div>
                <h5>BEWERKEN</h5>
                <h6>{evenement.projectnaam}</h6>
              </div>
              <div>
                <button className="button">Evenement Bewerken</button>
                <Toaster />
              </div>
            </div>
            <div className="container-wide-split">
              <div className="split-1">
                <div className="input-field big">
                  <h6>ALGEMEEN</h6>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="projectnaam">
                    Projectnaam*
                  </label>
                  <input
                    type="text"
                    id="projectnaam"
                    onChange={this.handleChange}
                    defaultValue={evenement.projectnaam}
                    required
                  ></input>
                </div>
                <div className="input-field small">
                  <select
                    className="browser-default"
                    defaultValue={evenement.status}
                    onChange={this.handleChange}
                    id="status"
                  >
                    <option value="" disabled selected>
                      Status*
                    </option>
                    <option value="Bevestigd">Bevestigd</option>
                    <option value="Concept">Concept</option>
                    <option value="Cancelled">Cancelled</option>
                    <option value="Feestdag">Feestdag</option>
                  </select>
                </div>
                {typeof evenement.startdatum !== 'number' && (
                  <div className="input-field small">
                    <input
                      type="date"
                      id="startdatum"
                      onChange={this.dateChange}
                      defaultValue={moment(
                        evenement.startdatum.toDate()
                      ).format('yyy-MM-DD')}
                      required
                    ></input>
                  </div>
                )}
                {typeof evenement.startdatum === 'number' && (
                  <div className="input-field small">
                    <input
                      type="date"
                      id="startdatum"
                      onChange={this.dateChange}
                      defaultValue={moment(evenement.startdatum).format(
                        'yyy-MM-DD'
                      )}
                      required
                    ></input>
                  </div>
                )}
                {typeof evenement.einddatum !== 'number' && (
                  <div className="input-field small">
                    <input
                      type="date"
                      id="einddatum"
                      onChange={this.dateChange}
                      defaultValue={moment(evenement.einddatum.toDate()).format(
                        'yyy-MM-DD'
                      )}
                      required
                    ></input>
                  </div>
                )}
                {typeof evenement.einddatum === 'number' && (
                  <div className="input-field small">
                    <input
                      type="date"
                      id="einddatum"
                      onChange={this.dateChange}
                      defaultValue={moment(evenement.einddatum).format(
                        'yyy-MM-DD'
                      )}
                      required
                    ></input>
                  </div>
                )}
                <div className="input-field small">
                  <select
                    className="browser-default"
                    onChange={this.handleChange}
                    id="organisator"
                    defaultValue={evenement.organisator}
                  >
                    <option value="" disabled selected>
                      Organisatie*
                    </option>
                    {facturen.map((factuur) => {
                      return (
                        <option key={factuur.id} value={factuur.title}>
                          {factuur.title}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="partner">
                    Partner
                  </label>
                  <input
                    type="text"
                    id="partner"
                    onChange={this.handleChange}
                    defaultValue={evenement.partner}
                  ></input>
                </div>
                <div className="input-field big">
                  <label className="active" htmlFor="draaiboeklink">
                    Draaiboek link
                  </label>
                  <input
                    type="text"
                    id="draaiboeklink"
                    onChange={this.handleChange}
                    defaultValue={evenement.draaiboeklink}
                  ></input>
                </div>
                <div className="input-field big">
                  <label className="active" htmlFor="adres">
                    Adres
                  </label>
                  <input
                    type="text"
                    id="adres"
                    onChange={this.handleChange}
                    defaultValue={evenement.adres}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="venue">
                    Venue
                  </label>
                  <input
                    type="text"
                    id="venue"
                    onChange={this.handleChange}
                    defaultValue={evenement.venue}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="capaciteit">
                    Capaciteit
                  </label>
                  <input
                    type="text"
                    id="capaciteit"
                    onChange={this.handleChange}
                    defaultValue={evenement.capaciteit}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="plaats">
                    Plaats*
                  </label>
                  <input
                    type="text"
                    id="plaats"
                    onChange={this.handleChange}
                    defaultValue={evenement.plaats}
                  ></input>
                </div>
                <div className="input-field small"></div>
                <div className="input-field big">
                  <h6 className="label">Duurzaamheid</h6>
                  <div className="radio-group">
                    <p className="radio-btn">
                      <label>
                        <input
                          name="duurzaamheid"
                          id="NGreen"
                          type="radio"
                          value="NotGreen"
                          onChange={this.handleRadioChange}
                          checked={this.state.duurzaamheid === 'NotGreen'}
                        />
                        <span>Not Green</span>
                      </label>
                    </p>
                    <p className="radio-btn">
                      <label>
                        <input
                          name="duurzaamheid"
                          id="Gbasic"
                          type="radio"
                          value="GreenBasic"
                          onChange={this.handleRadioChange}
                          checked={this.state.duurzaamheid === 'GreenBasic'}
                        />
                        <span>GreenBasic</span>
                      </label>
                    </p>
                    <p className="radio-btn">
                      <label>
                        <input
                          name="duurzaamheid"
                          id="GPlus"
                          type="radio"
                          value="Green+"
                          checked={this.state.duurzaamheid === 'Green+'}
                          onChange={this.handleRadioChange}
                        />
                        <span>Green+</span>
                      </label>
                    </p>
                    <p className="radio-btn">
                      <label>
                        <input
                          name="duurzaamheid"
                          id="GPlusPlus"
                          type="radio"
                          checked={this.state.duurzaamheid === 'Green++'}
                          value="Green++"
                          onChange={this.handleRadioChange}
                        />
                        <span>Green++</span>
                      </label>
                    </p>
                  </div>
                </div>
                <div className="input-field big">
                  <label className="active" htmlFor="opmerkingen">
                    Opmerkingen
                  </label>
                  <textarea
                    id="opmerkingen"
                    className="materialize-textarea"
                    onChange={this.handleChange}
                    defaultValue={evenement.opmerkingen}
                  ></textarea>
                </div>
                <div className="input-field big">
                  <h6>MANAGERS</h6>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="projecteigenaar">
                    Project eigenaar
                  </label>
                  <input
                    type="text"
                    id="projecteigenaar"
                    onChange={this.handleChange}
                    defaultValue={evenement.projecteigenaar}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="projectmanager">
                    Project manager
                  </label>
                  <input
                    type="text"
                    id="projectmanager"
                    onChange={this.handleChange}
                    defaultValue={evenement.projectmanager}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="marketingmanager">
                    Marketing manager
                  </label>
                  <input
                    type="text"
                    id="marketingmanager"
                    onChange={this.handleChange}
                    defaultValue={evenement.marketingmanager}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="productiemanager">
                    Productie manager
                  </label>
                  <input
                    type="text"
                    id="productiemanager"
                    onChange={this.handleChange}
                    defaultValue={evenement.productiemanager}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="horecamanager">
                    Horeca manager
                  </label>
                  <input
                    type="text"
                    id="horecamanager"
                    onChange={this.handleChange}
                    defaultValue={evenement.horecamanager}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="programmeur">
                    Programmeur
                  </label>
                  <input
                    type="text"
                    id="programmeur"
                    onChange={this.handleChange}
                    defaultValue={evenement.programmeur}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="financemanager">
                    Finance manager
                  </label>
                  <input
                    type="text"
                    id="financemanager"
                    onChange={this.handleChange}
                    defaultValue={evenement.financemanager}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="financeticketingmanager">
                    Finance &#38; ticketing manager
                  </label>
                  <input
                    type="text"
                    id="financeticketingmanager"
                    onChange={this.handleChange}
                    defaultValue={evenement.financeticketingmanager}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="administratie">
                    Administratie
                  </label>
                  <input
                    type="text"
                    id="administratie"
                    onChange={this.handleChange}
                    defaultValue={evenement.administratie}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="eventmanager">
                    Event manager
                  </label>
                  <input
                    type="text"
                    id="eventmanager"
                    onChange={this.handleChange}
                    defaultValue={evenement.eventmanager}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="leveranciercrew">
                    Leverancier crew show
                  </label>
                  <input
                    type="text"
                    id="leveranciercrew"
                    onChange={this.handleChange}
                    defaultValue={evenement.leveranciercrew}
                  ></input>
                </div>
                <div className="input-field small"></div>
                <div className="input-field big">
                  <h6>PRODUCTIE</h6>
                </div>
                <div className="input-field big">
                  <h6 className="label">Transport</h6>
                  <div className="radio-group">
                    <p className="radio-btn">
                      <label>
                        <input
                          name="transport"
                          id="intern"
                          type="radio"
                          value="Intern transport"
                          onChange={this.handleRadioChange}
                          checked={this.state.transport === 'Intern transport'}
                        />
                        <span>Intern transport</span>
                      </label>
                    </p>
                    <p className="radio-btn">
                      <label>
                        <input
                          name="transport"
                          id="extern"
                          type="radio"
                          value="Extern transport"
                          checked={this.state.transport === 'Extern transport'}
                          onChange={this.handleRadioChange}
                        />
                        <span>Extern transport</span>
                      </label>
                    </p>
                    <p className="radio-btn">
                      <label>
                        <input
                          name="transport"
                          id="geen"
                          type="radio"
                          checked={this.state.transport === 'Geen transport'}
                          value="Geen transport"
                          onChange={this.handleRadioChange}
                        />
                        <span>Geen transport</span>
                      </label>
                    </p>
                  </div>
                </div>
                <div className="input-field big">
                  <h6 className="label">Tourset</h6>
                  <div className="radio-group">
                    <p className="radio-btn">
                      <label>
                        <input
                          name="tourset"
                          id="klein"
                          type="radio"
                          value="Tourset klein"
                          onChange={this.handleRadioChange}
                          checked={this.state.tourset === 'Tourset klein'}
                        />
                        <span>Tourset klein</span>
                      </label>
                    </p>
                    <p className="radio-btn">
                      <label>
                        <input
                          name="tourset"
                          id="groot-extra"
                          type="radio"
                          value="Tourset groot met extra ground support"
                          checked={
                            this.state.tourset ===
                            'Tourset groot met extra ground support'
                          }
                          onChange={this.handleRadioChange}
                        />
                        <span>Tourset groot met extra ground support</span>
                      </label>
                    </p>
                    <p className="radio-btn">
                      <label>
                        <input
                          name="tourset"
                          id="aangepast-klein"
                          type="radio"
                          checked={
                            this.state.tourset === 'Aangepaste set klein'
                          }
                          value="Aangepaste set klein"
                          onChange={this.handleRadioChange}
                        />
                        <span>Aangepaste set klein</span>
                      </label>
                    </p>
                    <p className="radio-btn">
                      <label>
                        <input
                          name="tourset"
                          id="aangepast-groot"
                          type="radio"
                          checked={
                            this.state.tourset === 'Aangepaste set groot'
                          }
                          value="Aangepaste set groot"
                          onChange={this.handleRadioChange}
                        />
                        <span>Aangepaste set groot</span>
                      </label>
                    </p>
                    <p className="radio-btn">
                      <label>
                        <input
                          name="tourset"
                          id="big"
                          type="radio"
                          checked={
                            this.state.tourset === 'Big Party (layer podium)'
                          }
                          value="Big Party (layer podium)"
                          onChange={this.handleRadioChange}
                        />
                        <span>Big Party (layer podium)</span>
                      </label>
                    </p>
                  </div>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="decorproducent">
                    Decor / Technisch producent
                  </label>
                  <input
                    type="text"
                    id="decorproducent"
                    onChange={this.handleChange}
                    defaultValue={evenement.decorproducent}
                  ></input>
                </div>
                <div className="input-field small"></div>
              </div>
              <div className="split-2">
                <div className="input-field big">
                  <h6>LOCATIE</h6>
                </div>
                <div className="input-field big">
                  <h6 className="label">CONTACTPERSOON LOCATIE</h6>
                  <table className="striped">
                    <thead>
                      <tr>
                        <th>Naam</th>
                        <th>Telefoonnnummer</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    {this.state.locatiemanager != null && (
                      <tbody>
                        {this.state.locatiemanager.map((val, idx) => {
                          return (
                            <tr key={idx}>
                              <td>
                                <input
                                  type="text"
                                  id={'n-' + idx}
                                  data-id={idx}
                                  className="locmannaam"
                                  onChange={this.locManNameChange}
                                  defaultValue={val.locmannaam}
                                  required
                                ></input>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  id={'t-' + idx}
                                  data-id={idx}
                                  className="locmantel"
                                  onChange={this.locManTelChange}
                                  defaultValue={val.locmantel}
                                  required
                                ></input>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  id={'m-' + idx}
                                  data-id={idx}
                                  className="locmanmail"
                                  onChange={this.locManMailChange}
                                  defaultValue={val.locmanmail}
                                  required
                                ></input>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  onClick={() => this.removeLocMan(idx)}
                                >
                                  X
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                  <button
                    className="event-add"
                    type="button"
                    onClick={this.addLocMan}
                  >
                    <p>Contactpersoon toevoegen</p>
                    <span>
                      <Plus size={20} />
                    </span>
                  </button>
                </div>
                <div className="input-field small"></div>
                <div className="input-field big">
                  <h6>VEILIGHEID & VERGUNNINGEN</h6>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="wetgeving">
                    Wetgeving
                  </label>
                  <input
                    type="text"
                    id="wetgeving"
                    onChange={this.handleChange}
                    defaultValue={evenement.wetgeving}
                  ></input>
                  <span
                    class="helper-text"
                    data-error="wrong"
                    data-success="right"
                  >
                    Vergunning/ meldingsplicht / ontheffing drank
                  </span>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="beveiliging">
                    Beveiligingspartij
                  </label>
                  <input
                    type="text"
                    id="beveiliging"
                    onChange={this.handleChange}
                    defaultValue={evenement.beveiliging}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="medisch">
                    Medische dienst
                  </label>
                  <input
                    type="text"
                    id="medisch"
                    onChange={this.handleChange}
                    defaultValue={evenement.medisch}
                  ></input>
                </div>
                <div className="input-field small"></div>
                <div className="input-field big">
                  <h6>HORECA</h6>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="horecamanagershow">
                    Horeca manager (show)
                  </label>
                  <input
                    type="text"
                    id="horecamanagershow"
                    onChange={this.handleChange}
                    defaultValue={evenement.horecamanagershow}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="barleverancier">
                    Leverancier barpersoneel
                  </label>
                  <input
                    type="text"
                    id="barleverancier"
                    onChange={this.handleChange}
                    defaultValue={evenement.barleverancier}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="brouwerij">
                    Brouwerij
                  </label>
                  <input
                    type="text"
                    id="brouwerij"
                    onChange={this.handleChange}
                    defaultValue={evenement.brouwerij}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="restleverancier">
                    Leverancier restpakket
                  </label>
                  <input
                    type="text"
                    id="restleverancier"
                    onChange={this.handleChange}
                    defaultValue={evenement.restleverancier}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="beverageassortiment">
                    Beverage - Assortiment
                  </label>
                  <input
                    type="text"
                    id="beverageassortiment"
                    onChange={this.handleChange}
                    defaultValue={evenement.beverageassortiment}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="beverageactivaties">
                    Beverage - Activaties
                  </label>
                  <input
                    type="text"
                    id="beverageactivaties"
                    onChange={this.handleChange}
                    defaultValue={evenement.beverageactivaties}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="beveragespecialsbier">
                    Beverage - Specials bier op tap
                  </label>
                  <input
                    type="text"
                    id="beveragespecialsbier"
                    onChange={this.handleChange}
                    defaultValue={evenement.beveragespecialsbier}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="beverageshirt">
                    Beverage - T-shirts
                  </label>
                  <input
                    type="text"
                    id="beverageshirt"
                    onChange={this.handleChange}
                    defaultValue={evenement.beverageshirt}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="muntprijs">
                    Muntprijs
                  </label>
                  <input
                    type="text"
                    id="muntprijs"
                    onChange={this.handleChange}
                    defaultValue={evenement.muntprijs}
                  ></input>
                </div>
                <div className="input-field small"></div>
                <div className="input-field big">
                  <h6 className="label">CATERAARS</h6>
                  <table className="striped">
                    <thead>
                      <tr>
                        <th>Naam</th>
                        <th>Telefoonnnummer</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    {this.state.cateraar != null && (
                      <tbody>
                        {this.state.cateraar.map((val, idx) => {
                          return (
                            <tr key={idx}>
                              <td>
                                <input
                                  type="text"
                                  id={'n-' + idx}
                                  data-id={idx}
                                  className="cateraarnaam"
                                  onChange={this.cateraarNameChange}
                                  defaultValue={val.cateraarnaam}
                                  required
                                ></input>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  id={'t-' + idx}
                                  data-id={idx}
                                  className="cateraartel"
                                  onChange={this.cateraarTelChange}
                                  defaultValue={val.cateraartel}
                                  required
                                ></input>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  id={'m-' + idx}
                                  data-id={idx}
                                  className="cateraarmail"
                                  onChange={this.cateraarMailChange}
                                  defaultValue={val.cateraarmail}
                                  required
                                ></input>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  onClick={() => this.removeCateraar(idx)}
                                >
                                  X
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                  <button
                    className="event-add"
                    type="button"
                    onClick={this.addCateraar}
                  >
                    <p>Cateraar toevoegen</p>
                    <span>
                      <Plus size={20} />
                    </span>
                  </button>
                </div>
                <div className="input-field big">
                  <h6 className="label">HORECA OPBOUW</h6>
                  <table className="striped">
                    <thead>
                      <tr>
                        <th>Opbouwdag</th>
                        <th>Vanaf</th>
                        <th>Tot</th>
                        <th>Medewerkers</th>
                      </tr>
                    </thead>
                    {this.state.horecaopbouw != null && (
                      <tbody>
                        {this.state.horecaopbouw.map((val, idx) => {
                          return (
                            <tr key={idx}>
                              {typeof val.hopdatum !== 'number' && (
                                <td>
                                  <input
                                    type="date"
                                    id={'d-' + idx}
                                    data-id={idx}
                                    className="hopdatum"
                                    onChange={this.horecaOpbouwDateChange}
                                    defaultValue={moment(
                                      val.hopdatum.toDate()
                                    ).format('yyy-MM-DD')}
                                    required
                                  ></input>
                                </td>
                              )}
                              {typeof val.hopdatum === 'number' && (
                                <td>
                                  <input
                                    type="date"
                                    id={'d-' + idx}
                                    data-id={idx}
                                    className="hopdatum"
                                    onChange={this.horecaOpbouwDateChange}
                                    defaultValue={moment(val.hopdatum).format(
                                      'yyy-MM-DD'
                                    )}
                                    required
                                  ></input>
                                </td>
                              )}
                              <td>
                                <input
                                  type="time"
                                  id={'v-' + idx}
                                  data-id={idx}
                                  className="hopvanaf"
                                  onChange={this.horecaOpbouwVanafTimeChange}
                                  defaultValue={val.hopvanaf}
                                  required
                                ></input>
                              </td>
                              <td>
                                <input
                                  type="time"
                                  id={'t-' + idx}
                                  data-id={idx}
                                  className="hoptot"
                                  onChange={this.horecaOpbouwTotTimeChange}
                                  defaultValue={val.hoptot}
                                  required
                                ></input>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  id={'m-' + idx}
                                  data-id={idx}
                                  className="hopmedewerker"
                                  onChange={this.horecaOpbouwMedewerkerChange}
                                  defaultValue={val.hopmedewerker}
                                  required
                                ></input>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  onClick={() =>
                                    this.removeHorecaOpbouwDay(idx)
                                  }
                                >
                                  X
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                  <button
                    className="event-add"
                    type="button"
                    onClick={this.addHorecaOpbouwDay}
                  >
                    <p>Opbouwdag toevoegen</p>
                    <span>
                      <Plus size={20} />
                    </span>
                  </button>
                </div>
                <div className="input-field big">
                  <h6 className="label">HORECA SHOW</h6>
                  <table className="striped">
                    <thead>
                      <tr>
                        <th>Showdag</th>
                        <th>Vanaf</th>
                        <th>Tot</th>
                        <th>Medewerkers</th>
                      </tr>
                    </thead>
                    {this.state.horecashow != null && (
                      <tbody>
                        {this.state.horecashow.map((val, idx) => {
                          return (
                            <tr key={idx}>
                              {typeof val.hsdatum !== 'number' && (
                                <td>
                                  <input
                                    type="date"
                                    id={'d-' + idx}
                                    data-id={idx}
                                    className="hsdatum"
                                    onChange={this.horecaShowDateChange}
                                    defaultValue={moment(
                                      val.hsdatum.toDate()
                                    ).format('yyy-MM-DD')}
                                    required
                                  ></input>
                                </td>
                              )}
                              {typeof val.hsdatum === 'number' && (
                                <td>
                                  <input
                                    type="date"
                                    id={'d-' + idx}
                                    data-id={idx}
                                    className="hsdatum"
                                    onChange={this.horecaShowDateChange}
                                    defaultValue={moment(val.hsdatum).format(
                                      'yyy-MM-DD'
                                    )}
                                    required
                                  ></input>
                                </td>
                              )}
                              <td>
                                <input
                                  type="time"
                                  id={'v-' + idx}
                                  data-id={idx}
                                  className="hsvanaf"
                                  onChange={this.horecaShowVanafTimeChange}
                                  defaultValue={val.hsvanaf}
                                  required
                                ></input>
                              </td>
                              <td>
                                <input
                                  type="time"
                                  id={'t-' + idx}
                                  data-id={idx}
                                  className="hstot"
                                  onChange={this.horecaShowTotTimeChange}
                                  defaultValue={val.hstot}
                                  required
                                ></input>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  id={'m-' + idx}
                                  data-id={idx}
                                  className="hsmedewerker"
                                  onChange={this.horecaShowMedewerkerChange}
                                  defaultValue={val.hsmedewerker}
                                  required
                                ></input>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  onClick={() => this.removeHorecaShowDay(idx)}
                                >
                                  X
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                  <button
                    className="event-add"
                    type="button"
                    onClick={this.addHorecaShowDay}
                  >
                    <p>Showdag toevoegen</p>
                    <span>
                      <Plus size={20} />
                    </span>
                  </button>
                </div>
                <div className="input-field big">
                  <h6 className="label">HORECA AFBOUW</h6>
                  <table className="striped">
                    <thead>
                      <tr>
                        <th>Afbouwdag</th>
                        <th>Vanaf</th>
                        <th>Tot</th>
                        <th>Medewerkers</th>
                      </tr>
                    </thead>
                    {this.state.horecaafbouw != null && (
                      <tbody>
                        {this.state.horecaafbouw.map((val, idx) => {
                          return (
                            <tr key={idx}>
                              {typeof val.hafdatum !== 'number' && (
                                <td>
                                  <input
                                    type="date"
                                    id={'d-' + idx}
                                    data-id={idx}
                                    className="hafdatum"
                                    onChange={this.horecaAfbouwDateChange}
                                    defaultValue={moment(
                                      val.hafdatum.toDate()
                                    ).format('yyy-MM-DD')}
                                    required
                                  ></input>
                                </td>
                              )}
                              {typeof val.hafdatum === 'number' && (
                                <td>
                                  <input
                                    type="date"
                                    id={'d-' + idx}
                                    data-id={idx}
                                    className="hafdatum"
                                    onChange={this.horecaAfbouwDateChange}
                                    defaultValue={moment(val.hafdatum).format(
                                      'yyy-MM-DD'
                                    )}
                                    required
                                  ></input>
                                </td>
                              )}
                              <td>
                                <input
                                  type="time"
                                  id={'v-' + idx}
                                  data-id={idx}
                                  className="hafvanaf"
                                  onChange={this.horecaAfbouwVanafTimeChange}
                                  defaultValue={val.hafvanaf}
                                  required
                                ></input>
                              </td>
                              <td>
                                <input
                                  type="time"
                                  id={'t-' + idx}
                                  data-id={idx}
                                  className="haftot"
                                  onChange={this.horecaAfbouwTotTimeChange}
                                  defaultValue={val.haftot}
                                  required
                                ></input>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  id={'m-' + idx}
                                  data-id={idx}
                                  className="hafmedewerker"
                                  onChange={this.horecaAfbouwMedewerkerChange}
                                  defaultValue={val.hafmedewerker}
                                  required
                                ></input>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  onClick={() =>
                                    this.removeHorecaAfbouwDay(idx)
                                  }
                                >
                                  X
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                  <button
                    className="event-add"
                    type="button"
                    onClick={this.addHorecaAfbouwDay}
                  >
                    <p>Afbouwdag toevoegen</p>
                    <span>
                      <Plus size={20} />
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="input-field extra-wide">
              <table className="striped">
                <thead>
                  <tr>
                    <th>Showdagen</th>
                    <th>Start showtijd</th>
                    <th>Einde showtijd</th>
                    <th>Verwachte aantal bezoekers</th>
                  </tr>
                </thead>
                {this.state.showtijden != null && (
                  <tbody>
                    {this.state.showtijden.map((val, idx) => {
                      return (
                        <tr key={idx}>
                          {typeof val.datum !== 'number' && (
                            <td>
                              <input
                                type="date"
                                id={'day-' + idx}
                                data-id={idx}
                                className="datum"
                                onChange={this.showDateChange}
                                defaultValue={moment(val.datum.toDate()).format(
                                  'yyy-MM-DD'
                                )}
                                required
                              ></input>
                            </td>
                          )}
                          {typeof val.datum === 'number' && (
                            <td>
                              <input
                                type="date"
                                id={'day-' + idx}
                                data-id={idx}
                                className="datum"
                                onChange={this.showDateChange}
                                defaultValue={moment(val.datum).format(
                                  'yyy-MM-DD'
                                )}
                                required
                              ></input>
                            </td>
                          )}
                          <td>
                            <input
                              type="time"
                              id={'bg-' + idx}
                              data-id={idx}
                              className="begintijd"
                              onChange={this.showBeginTimeChange}
                              defaultValue={val.begintijd}
                              required
                            ></input>
                          </td>
                          <td>
                            <input
                              type="time"
                              id={'e-' + idx}
                              data-id={idx}
                              className="eindtijd"
                              onChange={this.showEndTimeChange}
                              defaultValue={val.eindtijd}
                              required
                            ></input>
                          </td>
                          <td>
                            <input
                              type="text"
                              id={'e-' + idx}
                              data-id={idx}
                              className="estcapacity"
                              onChange={this.showCapacityChange}
                              defaultValue={val.estcapacity}
                              required
                            ></input>
                          </td>
                          <td>
                            <button
                              type="button"
                              onClick={() => this.removeShowDay(idx)}
                            >
                              X
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
              <button
                className="event-add"
                type="button"
                onClick={this.addShowDay}
              >
                <p>Showdag toevoegen</p>
                <span>
                  <Plus size={20} />
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const evenementen = state.firestore.data.evenementen;
  const evenement = evenementen ? evenementen[id] : null;
  return {
    evenement: evenement,
    auth: state.firebase.auth,
    facturen: state.firestore.ordered.facturen,
  };
};

const mapDistpatchToProps = (dispatch) => {
  return {
    editEvenement: (evenement) =>
      dispatch(
        editEvenement(
          evenement,
          evenement.id
          // evenement.img
        )
      ),
  };
};

export default compose(
  connect(mapStateToProps, mapDistpatchToProps),
  firestoreConnect([
    {
      collection: 'evenementen',
    },
  ])
)(EditEvenement);
