import React from 'react';
import { Link } from 'react-router-dom';
import SignedInLinks from './SignedInLinks';
import SignedOutLinks from './SignedOutLinks';
import { connect } from 'react-redux';
import logo from '../../images/THISISLIVE_favicon.png';
// import christmashat from '../../images/Christmas-Hat-PNG-HD.png';

const Navbar = (props) => {
  const { auth, profile } = props;
  const links = auth.uid ? (
    <SignedInLinks profile={profile} />
  ) : (
    <SignedOutLinks />
  );

  // const festive = <></>;

  const festive = profile.festiveView ? (
    <div className="logo-extra">
      {/* <img src={christmashat} alt="kerstmuts" /> */}
    </div>
  ) : null;

  return (
    <nav className="nav-wrapper darken-3">
      <div className="container">
        <Link to={'/'} className="brand-logo">
          {festive}
          <img className="logo" src={logo} alt="logo" />
          Eventplanner | THIS IS LIVE
        </Link>
        {links}
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

export default connect(mapStateToProps)(Navbar);
