import React, { Component } from 'react';
import SelectedHeaderList from '../../context/SelectedHeaderList';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';
import { X, ArrowLeft } from 'react-feather';
import { selectedEventsContext } from '../../context/SelectedEventList';

import moment from 'moment';
import 'moment/locale/nl';

import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  InfiniteHits,
  SearchBox,
  Configure,
  createInfiniteHitsSessionStorageCache,
} from 'react-instantsearch-dom';
import qs from 'qs';
import { getSecondPart, getThirdPart } from '../../functions/stringSplitting';

moment.locale('nl');
const DEBOUNCE_TIME = 700;

const createURL = (state) => `?${qs.stringify(state)}`;

const searchStateToUrl = (props, searchState) =>
  searchState ? `${props.location.pathname}${createURL(searchState)}` : '';

const urlToSearchState = (location) => qs.parse(location.search.slice(1));

const searchClient = algoliasearch(
  'O5GHKO8MSQ',
  'c5391fcd19cca2c5b4ed4f863e457b5e'
);

const sessionStorageCache = createInfiniteHitsSessionStorageCache();

class NotifyLogs extends Component {
  static contextType = selectedEventsContext;

  constructor(props) {
    super(props);
    this.state = {
      startfilter: moment().subtract(2, 'M').valueOf(),
      eindfilter: moment().add(1, 'd').valueOf(),
      searchState: urlToSearchState(this.props.location),
      lastLocation: this.props.location,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location !== state.lastLocation) {
      return {
        searchState: urlToSearchState(props.location),
        lastLocation: props.location,
      };
    }

    return null;
  }

  onSearchStateChange = (searchState) => {
    clearTimeout(this.debouncedSetState);

    this.debouncedSetState = setTimeout(() => {
      this.props.history.push(
        searchStateToUrl(this.props, searchState),
        searchState
      );
    }, DEBOUNCE_TIME);

    this.setState({ searchState });
  };

  handleBack = (e) => {
    e.preventDefault();
    window.history.back();
  };

  handleStartFilterChange = (e) => {
    this.setState(() => {
      return { startfilter: moment(e.target.value).valueOf() };
    });
  };

  handleEindFilterChange = (e) => {
    this.setState(() => {
      return { eindfilter: moment(e.target.value).valueOf() };
    });
  };

  render() {
    const { auth } = this.props; // eslint-disable-next-line

    if (!auth.uid) return <Redirect to="/signin" />;
    return (
      <div className="dashboard container-wide">
        <button className="btn-icon back-btn" onClick={this.handleBack}>
          <span>
            <ArrowLeft size={20} />
          </span>
          Back
        </button>
        <div className="row">
          <div className="col s12">
            <div className="title">
              <h1>NOTIFICATIES</h1>
            </div>
            <InstantSearch
              searchClient={searchClient}
              indexName="Notifications"
              onSearchStateChange={this.onSearchStateChange}
              createURL={createURL}
            >
              <Configure
                filters={`time:${this.state.startfilter} TO ${this.state.eindfilter}`}
              />
              <div className="searchBar">
                <div className="actions">
                  <div className="utility-bar">
                    <SelectedHeaderList>
                      <div className="first-row">
                        <SearchBox
                          reset={<X size={20} />}
                          translations={{
                            placeholder:
                              'Zoekbalk voor projecten / partners / plaats / provincie / etc…',
                          }}
                        />
                      </div>
                      <div className="second-row">
                        <div className="date-filter">
                          <div className="date-container">
                            <input
                              type="date"
                              className="datum filter"
                              defaultValue={moment(
                                this.state.startfilter
                              ).format('YYYY-MM-DD')}
                              onChange={this.handleStartFilterChange}
                            ></input>
                          </div>
                          <p>Tot</p>
                          <div className="date-container">
                            <input
                              type="date"
                              className="datum filter"
                              defaultValue={moment(
                                this.state.eindfilter
                              ).format('YYYY-MM-DD')}
                              onChange={this.handleEindFilterChange}
                            ></input>
                          </div>
                        </div>
                      </div>
                    </SelectedHeaderList>
                  </div>
                </div>
              </div>
              <div className="notification-list">
                <table className="striped">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Evenement</th>
                      <th>Taak</th>
                      <th>Aanpassingen</th>
                      <th>Wie</th>
                      <th>Datum</th>
                    </tr>
                  </thead>
                  <InfiniteHits
                    showPrevious={true}
                    hitComponent={Hit}
                    cache={sessionStorageCache}
                  />
                </table>
              </div>
            </InstantSearch>
          </div>
        </div>
      </div>
    );
  }
}

function Hit(props) {
  console.log(props);
  return (
    <>
      <td>{props.hit.type}</td>
      <td>{props.hit.eventname}</td>
      <td>{props.hit.task}</td>
      <td>
        {props.hit.changes && (
          <>
            {Object.values(props.hit.changes).map((val, k) => {
              if (val.startsWith(' E ')) {
                if (
                  moment.unix(getSecondPart(val, ' E ')).format('DD-MM-yyy') ===
                  moment.unix(getThirdPart(val, ' E ')).format('DD-MM-yyy')
                ) {
                  return null;
                } else {
                  return (
                    <p key={k}>
                      {'Einddatum: ' +
                        moment
                          .unix(getSecondPart(val, ' E '))
                          .format('DD-MM-yyy') +
                        ' -> ' +
                        moment
                          .unix(getThirdPart(val, ' E '))
                          .format('DD-MM-yyy')}
                    </p>
                  );
                }
              }
              if (val.startsWith(' S ')) {
                if (
                  moment.unix(getSecondPart(val, ' S ')).format('DD-MM-yyy') ===
                  moment.unix(getThirdPart(val, ' S ')).format('DD-MM-yyy')
                ) {
                  return null;
                } else {
                  return (
                    <p key={k}>
                      {'Startdatum: ' +
                        moment
                          .unix(getSecondPart(val, ' S '))
                          .format('DD-MM-yyy') +
                        ' -> ' +
                        moment
                          .unix(getThirdPart(val, ' S '))
                          .format('DD-MM-yyy')}
                    </p>
                  );
                }
              } else {
                return <p key={k}>{val}</p>;
              }
            })}
          </>
        )}
      </td>
      {props.hit.type === 'create' && <td>{props.hit.eventcreator}</td>}
      {props.hit.type === 'update' && <td>{props.hit.eventupdater}</td>}
      {props.hit.type === 'delete' && <td></td>}
      <td>{moment(props.hit.time).format('D-M-YYYY H:mm')}</td>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

export default compose(connect(mapStateToProps, null))(NotifyLogs, Hit);
