import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { editEvenementImage } from '../../store/actions/evenementActions';
import toast, { Toaster } from 'react-hot-toast';

const notify = () => toast.success('Afbeelding is bijgewerkt!');

const EditImageModal = (props) => {
  const [imag, setImag] = useState([]);
  const handleImageUpload = (e) => {
    e.preventDefault();
    if (e.target.files[0]) {
      setImag({
        [e.target.id]: e.target.files[0],
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.editEvenementImage(props.evenement, imag);
    notify();
    setTimeout(() => {
      props.history.push('/');
    }, 3000);
  };

  return (
    <div className="modal" id="imageEditModal">
      <Toaster />
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Afbeelding bewerken</h4>
        </div>
        <form onSubmit={handleSubmit} className="full-width">
          <div className="modal-body">
            <div className="input-field upload big">
              <label htmlFor="image">Afbeelding uploaden:</label>
              <input
                type="file"
                name="image"
                id="img"
                onChange={(e) => handleImageUpload(e)}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button type="submit" className="button">
              Afbeelding bijwerken
            </button>
            <button
              className="button"
              onClick={(e) => {
                e.preventDefault();
                const modal = document.getElementById('imageEditModal');
                modal.style.display = 'none';
              }}
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.event.id;
  const evenementen = state.firestore.data.evenementen;
  const evenement = evenementen ? evenementen[id] : null;
  return {
    evenement: evenement,
    auth: state.firebase.auth,
  };
};

const mapDistpatchToProps = (dispatch) => {
  return {
    editEvenementImage: (evenement, image) =>
      dispatch(editEvenementImage(evenement, evenement.id, image)),
  };
};

export default compose(
  connect(mapStateToProps, mapDistpatchToProps),
  firestoreConnect([
    {
      collection: 'evenementen',
    },
  ])
)(EditImageModal);
