import React, { useContext, useEffect, useState } from 'react';
import { selectedEventsContext } from '../../context/SelectedEventList';
import { selectedHeaderContext } from '../../context/SelectedHeaderList';
import EventExport from '../dashboard/EventExport';

const ExportModal = (props) => {
  // eslint-disable-next-line
  const [events, setEvents] = useContext(selectedEventsContext);
  const [headers, setHeaders] = useContext(selectedHeaderContext);
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    if (events.length !== 0) {
      for (var i = 0; i < events.length; i++) {
        Object.keys(events[i]).forEach(function (key) {
          if (!keys.includes(key)) {
            setKeys([...keys, key]);
          }
        });
      }
    }
    console.log(keys);
  });

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      // Get full event
      setHeaders([...headers, e.target.value]);
    } else {
      // Remove event if not checked but still in list
      setHeaders(headers.filter((hdr) => hdr !== e.target.value));
    }
  };

  if (!props.show) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Export evenement</h4>
        </div>
        <div className="modal-body">
          <p>Selecteer welke kolommen je wilt exporteren:</p>
          <div className="modal-checks">
            <h6>STANDAARD</h6>
            <div className="modal-checks-box">
              {keys
                .filter((str) => str.includes('_') === false)
                .filter((str) => str.includes('ID') === false)
                .filter((str) => str.includes('lastmodified') === false)
                .filter((str) => str.includes('img') === false)
                .filter((str) => str.includes('id') === false)
                .filter((str) => str.includes('createdBy') === false)
                .filter((str) => str.includes('updatedBy') === false)
                .filter(
                  (str) =>
                    str === 'startdatum' ||
                    str === 'einddatum' ||
                    str === 'projectnaam' ||
                    str === 'status' ||
                    str === 'showtijden'
                )
                .sort((a, b) => a.localeCompare(b))
                .map((key) => (
                  <p>
                    <label>
                      <input
                        type="checkbox"
                        id="proje"
                        className="filled-in"
                        defaultChecked={false}
                        value={key}
                        onChange={handleCheckboxChange}
                      />
                      <span>{key}</span>
                    </label>
                  </p>
                ))}
            </div>
            <h6>Algemene informatie</h6>
            <div className="modal-checks-box">
              {keys
                .filter((str) => str.includes('_') === false)
                .filter((str) => str.includes('ID') === false)
                .filter((str) => str.includes('lastmodified') === false)
                .filter((str) => str.includes('img') === false)
                .filter((str) => str.includes('id') === false)
                .filter((str) => str.includes('createdBy') === false)
                .filter((str) => str.includes('updatedBy') === false)
                .filter(
                  (str) =>
                    str === 'draaiboeklink' ||
                    str === 'organisator' ||
                    str === 'partner' ||
                    str === 'adres' ||
                    str === 'capaciteit' ||
                    str === 'venue' ||
                    str === 'duurzaamheid' ||
                    str === 'opmerkingen' ||
                    str === 'plaats'
                )
                .sort((a, b) => a.localeCompare(b))
                .map((key) => (
                  <p>
                    <label>
                      <input
                        type="checkbox"
                        id="proje"
                        className="filled-in"
                        defaultChecked={false}
                        value={key}
                        onChange={handleCheckboxChange}
                      />
                      <span>{key}</span>
                    </label>
                  </p>
                ))}
              <p>
                <label>
                  <input
                    type="checkbox"
                    id="proje"
                    className="filled-in"
                    defaultChecked={false}
                    value="duurzaamheid"
                    onChange={handleCheckboxChange}
                  />
                  <span>Duurzaamheid</span>
                </label>
              </p>
            </div>
            <h6>Project team</h6>
            <div className="modal-checks-box">
              {keys
                .filter((str) => str.includes('_') === false)
                .filter((str) => str.includes('ID') === false)
                .filter((str) => str.includes('lastmodified') === false)
                .filter((str) => str.includes('img') === false)
                .filter((str) => str.includes('id') === false)
                .filter((str) => str.includes('createdBy') === false)
                .filter((str) => str.includes('updatedBy') === false)
                .filter(
                  (str) =>
                    str === 'projecteigenaar' ||
                    str === 'projectmanager' ||
                    str === 'productiemanager' ||
                    str === 'marketingmanager' ||
                    str === 'horecamanager' ||
                    str === 'programmeur' ||
                    str === 'financemanager' ||
                    str === 'financeticketingmanager' ||
                    str === 'administratie' ||
                    str === 'eventmanager' ||
                    str === 'leveranciercrew'
                )
                .sort((a, b) => a.localeCompare(b))
                .map((key) => (
                  <p>
                    <label>
                      <input
                        type="checkbox"
                        id="proje"
                        className="filled-in"
                        defaultChecked={false}
                        value={key}
                        onChange={handleCheckboxChange}
                      />
                      <span>{key}</span>
                    </label>
                  </p>
                ))}
            </div>
            <h6>Horeca</h6>
            <div className="modal-checks-box">
              {keys
                .filter((str) => str.includes('_') === false)
                .filter((str) => str.includes('ID') === false)
                .filter((str) => str.includes('lastmodified') === false)
                .filter((str) => str.includes('img') === false)
                .filter((str) => str.includes('id') === false)
                .filter((str) => str.includes('createdBy') === false)
                .filter((str) => str.includes('updatedBy') === false)
                .filter(
                  (str) =>
                    str === 'horecamanagershow' ||
                    str === 'barleverancier' ||
                    str === 'brouwerij' ||
                    str === 'restleverancier' ||
                    str === 'beverageassortiment' ||
                    str === 'beverageactivaties' ||
                    str === 'beveragespecialsbier' ||
                    str === 'beverageshirt' ||
                    str === 'muntprijs'
                )
                .sort((a, b) => a.localeCompare(b))
                .map((key) => (
                  <p>
                    <label>
                      <input
                        type="checkbox"
                        id="proje"
                        className="filled-in"
                        defaultChecked={false}
                        value={key}
                        onChange={handleCheckboxChange}
                      />
                      <span>{key}</span>
                    </label>
                  </p>
                ))}
            </div>
            <h6>Veiligheid & Vergunningen</h6>
            <div className="modal-checks-box">
              {keys
                .filter((str) => str.includes('_') === false)
                .filter((str) => str.includes('ID') === false)
                .filter((str) => str.includes('lastmodified') === false)
                .filter((str) => str.includes('img') === false)
                .filter((str) => str.includes('id') === false)
                .filter((str) => str.includes('createdBy') === false)
                .filter((str) => str.includes('updatedBy') === false)
                .filter(
                  (str) =>
                    str === 'wetgeving' ||
                    str === 'beveiliging' ||
                    str === 'medisch'
                )
                .sort((a, b) => a.localeCompare(b))
                .map((key) => (
                  <p>
                    <label>
                      <input
                        type="checkbox"
                        id="proje"
                        className="filled-in"
                        defaultChecked={false}
                        value={key}
                        onChange={handleCheckboxChange}
                      />
                      <span>{key}</span>
                    </label>
                  </p>
                ))}
            </div>
            <h6>Productie</h6>
            <div className="modal-checks-box">
              {keys
                .filter((str) => str.includes('_') === false)
                .filter((str) => str.includes('ID') === false)
                .filter((str) => str.includes('lastmodified') === false)
                .filter((str) => str.includes('img') === false)
                .filter((str) => str.includes('id') === false)
                .filter((str) => str.includes('createdBy') === false)
                .filter((str) => str.includes('updatedBy') === false)
                .filter(
                  (str) =>
                    str === 'transport' ||
                    str === 'tourset' ||
                    str === 'decorproducent'
                )
                .sort((a, b) => a.localeCompare(b))
                .map((key) => (
                  <p>
                    <label>
                      <input
                        type="checkbox"
                        id="proje"
                        className="filled-in"
                        defaultChecked={false}
                        value={key}
                        onChange={handleCheckboxChange}
                      />
                      <span>{key}</span>
                    </label>
                  </p>
                ))}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <EventExport />
          <button className="button" onClick={props.onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExportModal;
