import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  updateEmail,
  updatePwd,
  updateDashboardView,
} from '../../store/actions/authActions';
import { isMobile } from 'react-device-detect';
import { ArrowLeft } from 'react-feather';
import toast, { Toaster } from 'react-hot-toast';
import firebase from 'firebase/compat/app';

const notifyEmail = () => toast.success('Email is bijgewerkt!');
const notifyPwd = () => toast.success('Wachtwoord is bijgewerkt!');
const notifyView = () =>
  toast.success('Het dashboard is veranderd naar jou persoonlijke weergave!');

class Account extends Component {
  state = {
    userCurrentEmail: this.props.auth.email,
    userNewEmail: '',
    userCurrentPwd: '',
    userNewPwd: '',
    dashboardview: {
      administratie: false,
      adres: false,
      barleverancier: false,
      beveiliging: false,
      brouwerij: false,
      capaciteit: false,
      datums: false,
      decorproducent: false,
      draaiboeklink: false,
      duurzaamheid: false,
      financemanager: false,
      horecamanager: false,
      horecamanagershow: false,
      marketingmanager: false,
      leveranciercrew: false,
      opmerkingen: false,
      organisator: false,
      partner: false,
      plaats: false,
      productiemanager: false,
      programmeur: false,
      projecteigenaar: false,
      projectmanager: false,
      projectnaam: false,
      restleverancier: false,
      status: false,
      transport: false,
      tourset: false,
      venue: false,
    },
    festiveView: this.props.profile.festiveView,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleViewChange = (e) => {
    console.log(e.target.checked);
    if (e.target.checked) {
      firebase
        .firestore()
        .collection('users')
        .doc(this.props.auth.uid)
        .update({
          festiveView: true,
        })
        .then(() => {
          console.log('Succes True!');
        })
        .catch(() => {
          console.log('Gefaald!');
        });
    } else {
      firebase
        .firestore()
        .collection('users')
        .doc(this.props.auth.uid)
        .update({
          festiveView: false,
        })
        .then(() => {
          console.log('Succes False!');
        })
        .catch(() => {
          console.log('Gefaald!');
        });
    }
  };

  handleBack = (e) => {
    e.preventDefault();
    window.history.back();
  };

  handleCheckboxChange = (e) => {
    this.setState({ dashboardview: this.props.profile.dashboardview });
    let dashboardviewItems = {
      ...this.state.dashboardview,
      [e.target.id]: e.target.checked,
    };
    console.log(dashboardviewItems);
    this.setState(
      {
        dashboardview: dashboardviewItems,
      },
      () => {
        console.log('New state set: ');
        console.log(this.state.dashboardview);
      }
    );
  };

  handleSubmitEmail = (e) => {
    e.preventDefault();
    this.props.updateEmail(this.state.userCurrentPwd, this.state.userNewEmail);
    notifyEmail();
    setTimeout(() => {
      this.props.history.push('/');
    }, 3000);
  };

  handleSubmitPwd = (e) => {
    e.preventDefault();
    this.props.updatePwd(this.state.userCurrentPwd, this.state.userNewPwd);
    notifyPwd();
    setTimeout(() => {
      this.props.history.push('/');
    }, 3000);
  };

  handleSubmitView = (e) => {
    e.preventDefault();
    this.props.updateDashboardView(this.state.dashboardview);
    notifyView();
    setTimeout(() => {
      this.props.history.push('/');
    }, 3000);
  };

  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to="/signin" />;
    return (
      <div className="container-wide section">
        <Toaster />
        <button className="btn-icon back-btn" onClick={this.handleBack}>
          {' '}
          <span>
            <ArrowLeft size={20} />
          </span>
          Back
        </button>
        <div className="title">
          <h1>ACCOUNT</h1>
        </div>
        <div className="account-detail">
          <button className="btn btn-floating pink lighten-1">
            {this.props.profile.initials}
          </button>
          <div className="account-detail-info">
            <h3>
              {this.props.profile.firstname + ' ' + this.props.profile.lastname}
            </h3>
            <h7>{this.state.userCurrentEmail}</h7> <br />
            <h7>{this.props.profile.role}</h7>
          </div>
        </div>
        {isMobile === false && (
          <>
            {this.props.profile.dashboardview && (
              <div className="account-dashboard">
                <h4>Verander dashboard overzicht</h4>
                <h5>Feestdagen extra's</h5>
                <div className="switch">
                  <label>
                    Uit
                    <input
                      type="checkbox"
                      onChange={this.handleViewChange}
                      defaultChecked={this.props.profile.festiveView}
                    />
                    <span className="lever"></span>
                    Aan
                  </label>
                </div>
                <br />
                <h5>Dashboard weergave</h5>
                <form onSubmit={this.handleSubmitView}>
                  <div className="dashboard-form">
                    {/* <div className="overlayDisabled">
                      <h1>Tijdelijk onbruikbaar!</h1>
                    </div> */}
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          id="administratie"
                          className="filled-in CHCH"
                          onChange={this.handleCheckboxChange}
                        />
                        <span>Administratie</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          id="adres"
                          className="filled-in CHCH"
                          onChange={this.handleCheckboxChange}
                        />
                        <span>Adres</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          id="barleverancier"
                          className="filled-in CHCH"
                          onChange={this.handleCheckboxChange}
                        />
                        <span>Barleverancier</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          id="beveiliging"
                          className="filled-in CHCH"
                          onChange={this.handleCheckboxChange}
                        />
                        <span>beveiliging</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          id="brouwerij"
                          className="filled-in CHCH"
                          onChange={this.handleCheckboxChange}
                        />
                        <span>brouwerij</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          id="capaciteit"
                          className="filled-in CHCH"
                          onChange={this.handleCheckboxChange}
                        />
                        <span>capaciteit</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          id="datums"
                          className="filled-in CHCH"
                          onChange={this.handleCheckboxChange}
                        />
                        <span>Start- / einddatum</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          id="decorproducent"
                          className="filled-in CHCH"
                          onChange={this.handleCheckboxChange}
                        />
                        <span>Decor / Technisch Producent</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          id="draaiboeklink"
                          className="filled-in CHCH"
                          onChange={this.handleCheckboxChange}
                        />
                        <span>Draaiboek link</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          id="duurzaamheid"
                          className="filled-in CHCH"
                          onChange={this.handleCheckboxChange}
                        />
                        <span>Duurzaamheid</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          id="financemanager"
                          className="filled-in CHCH"
                          onChange={this.handleCheckboxChange}
                        />
                        <span>Financemanager</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          id="financeticketingmanager"
                          className="filled-in CHCH"
                          onChange={this.handleCheckboxChange}
                        />
                        <span>Finance &#38; ticketing manager</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          id="horecamanager"
                          className="filled-in CHCH"
                          onChange={this.handleCheckboxChange}
                        />
                        <span>Horecamanager</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          id="horecamanagershow"
                          className="filled-in CHCH"
                          onChange={this.handleCheckboxChange}
                        />
                        <span>Horecamanager (show)</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          id="marketingmanager"
                          className="filled-in CHCH"
                          onChange={this.handleCheckboxChange}
                        />
                        <span>Marketingmanager</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          id="leveranciercrew"
                          className="filled-in CHCH"
                          onChange={this.handleCheckboxChange}
                        />
                        <span>Leverancier crew show</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          id="opmerkingen"
                          className="filled-in CHCH"
                          onChange={this.handleCheckboxChange}
                        />
                        <span>Opmerkingen</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          id="organisator"
                          className="filled-in CHCH"
                          onChange={this.handleCheckboxChange}
                        />
                        <span>Organisator</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          id="partner"
                          className="filled-in CHCH"
                          onChange={this.handleCheckboxChange}
                        />
                        <span>Partner</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          id="plaats"
                          className="filled-in CHCH"
                          onChange={this.handleCheckboxChange}
                        />
                        <span>Plaats</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          id="productiemanager"
                          className="filled-in CHCH"
                          onChange={this.handleCheckboxChange}
                        />
                        <span>Productiemanager</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          id="programmeur"
                          className="filled-in CHCH"
                          onChange={this.handleCheckboxChange}
                        />
                        <span>Programmeur</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          id="projecteigenaar"
                          className="filled-in CHCH"
                          onChange={this.handleCheckboxChange}
                        />
                        <span>Projecteigenaar</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          id="projectmanager"
                          className="filled-in CHCH"
                          onChange={this.handleCheckboxChange}
                        />
                        <span>Projectmanager</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          id="projectnaam"
                          className="filled-in CHCH"
                          onChange={this.handleCheckboxChange}
                        />
                        <span>Projectnaam</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          id="restleverancier"
                          className="filled-in CHCH"
                          onChange={this.handleCheckboxChange}
                        />
                        <span>Restleverancier</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          id="status"
                          className="filled-in CHCH"
                          onChange={this.handleCheckboxChange}
                        />
                        <span>Status</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          id="transport"
                          className="filled-in CHCH"
                          onChange={this.handleCheckboxChange}
                        />
                        <span>Transport</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          id="tourset"
                          className="filled-in CHCH"
                          onChange={this.handleCheckboxChange}
                        />
                        <span>Tourset</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          id="venue"
                          className="filled-in CHCH"
                          onChange={this.handleCheckboxChange}
                        />
                        <span>Venue</span>
                      </label>
                    </p>
                  </div>
                  <button className="button" type="submit">
                    Verander weergave dashboard
                  </button>
                </form>
              </div>
            )}
          </>
        )}
        <div className="account-change">
          <div className="account-change-email">
            <h4>VERANDER EMAIL</h4>
            <p>Huidige email: {this.state.userCurrentEmail}</p>
            <form onSubmit={this.handleSubmitEmail} autoComplete="off">
              <div className="input-field small">
                <label htmlFor="userNewEmail">Nieuw emailadres</label>
                <input
                  type="text"
                  id="userNewEmail"
                  onChange={this.handleChange}
                  autoComplete="off"
                  required
                ></input>
              </div>
              <div className="input-field small">
                <label htmlFor="userCurrentPwd">Huidige Wachtwoord</label>
                <input
                  type="password"
                  id="userCurrentPwd"
                  onChange={this.handleChange}
                  autoComplete="new-password"
                  required
                ></input>
              </div>
              <button className="button" type="submit">
                Verander email
              </button>
            </form>
          </div>
          <div className="account-change-password">
            <h4>VERANDER WACHTWOORD</h4>
            <form onSubmit={this.handleSubmitPwd} autoComplete="off">
              <div className="input-field small">
                <label htmlFor="userCurrentPwd">Huidig wachtwoord</label>
                <input
                  type="password"
                  id="userCurrentPwd"
                  onChange={this.handleChange}
                  autoComplete="off"
                  required
                ></input>
              </div>
              <div className="input-field small">
                <label htmlFor="userNewPwd">Nieuw wachtwoord</label>
                <input
                  type="password"
                  id="userNewPwd"
                  onChange={this.handleChange}
                  autoComplete="new-password"
                  required
                ></input>
              </div>
              <button className="button" type="submit">
                Verander wachtwoord
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth,
  };
};

const mapDistpatchToProps = (dispatch) => {
  return {
    updateEmail: (currentPWD, email) =>
      dispatch(updateEmail(currentPWD, email)),
    updatePwd: (currentPWD, newPDW) => dispatch(updatePwd(currentPWD, newPDW)),
    updateDashboardView: (dashboardview) =>
      dispatch(updateDashboardView(dashboardview)),
  };
};

export default connect(mapStateToProps, mapDistpatchToProps)(Account);
