import React from 'react';
import { Edit2, Trash } from 'react-feather';
import { Link } from 'react-router-dom';
import firebase from 'firebase/compat';

export default function FactuurBlock({ id, title, key, authorised }) {
  const handleDelete = (e) => {
    e.preventDefault();
    firebase.firestore().collection('facturen').doc(id).delete();
  };

  return (
    <div className="block-container" key={key}>
      <div className="block-title">{title}</div>
      <div className="block-actions">
        <Link to={`/edit-facturen/${id}`}>
          <Edit2 />
        </Link>
        {authorised && ( // eslint-disable-next-line
          <a onClick={handleDelete} style={{ marginLeft: '20px' }}>
            <Trash style={{ color: '#f52566' }} />
          </a>
        )}
      </div>
    </div>
  );
}
