import { store } from '../../index';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

export const uploadPhoto = (img, path) => {
  const imgRef = firebase
    .storage()
    .ref()
    .child('images/' + path);

  imgRef.put(img).then(function (snapshot) {
    store.dispatch({ type: 'UPLOAD_IMAGE_SUCCESS', img });
  });
  console.log(imgRef);
  return imgRef;
};

export const GetPhoto = (path) => {
  if (typeof path !== 'undefined') {
    const pathRef = firebase.storage().ref(path);
    return pathRef.getDownloadURL();
  }
};
