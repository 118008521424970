import React, { Component, useContext, useEffect, useState } from 'react';
import SelectedHeaderList from '../../context/SelectedHeaderList';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Redirect, Link } from 'react-router-dom';
import { Plus, X, Download, Eye, Trash, Copy } from 'react-feather';
import {
  deleteEvenement,
  copyEvenement,
} from '../../store/actions/evenementActions';
import { selectedEventsContext } from '../../context/SelectedEventList';
import { isMobile } from 'react-device-detect';
import BigCalendar from './BigCalendar';
import firebase from 'firebase/compat/app';

import ExportModal from '../models/ExportModal';
import EventAdd from '../models/EventAdd';

import { GiThreeLeaves } from 'react-icons/gi';
import { SlCalender } from 'react-icons/sl';
import { FiList } from 'react-icons/fi';

import moment from 'moment';
import 'moment/locale/nl';

import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  InfiniteHits,
  SearchBox,
  SortBy,
  Configure,
  RefinementList,
  createInfiniteHitsSessionStorageCache,
} from 'react-instantsearch-dom';
import qs from 'qs';
import SelectAll from './SelectAll';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

moment.locale('nl');
const DEBOUNCE_TIME = 700;

const createURL = (state) => `?${qs.stringify(state)}`;

const searchStateToUrl = (props, searchState) =>
  searchState ? `${props.location.pathname}${createURL(searchState)}` : '';

const urlToSearchState = (location) => qs.parse(location.search.slice(1));

const searchClient = algoliasearch(
  'O5GHKO8MSQ',
  'c5391fcd19cca2c5b4ed4f863e457b5e'
);

const sessionStorageCache = createInfiniteHitsSessionStorageCache();

class Dashboard extends Component {
  /* eslint-disable */
  static contextType = selectedEventsContext;

  constructor(props) {
    super(props);
    this.state = {
      exportshow: false,
      addshow: false,
      calendar: false,
      startfilter: moment().subtract(1, 'd').valueOf(),
      eindfilter: moment().add(2, 'y').valueOf(),
      searchState: urlToSearchState(this.props.location),
      lastLocation: this.props.location,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location !== state.lastLocation) {
      return {
        searchState: urlToSearchState(props.location),
        lastLocation: props.location,
      };
    }

    return null;
  }

  onSearchStateChange = (searchState) => {
    clearTimeout(this.debouncedSetState);

    this.debouncedSetState = setTimeout(() => {
      this.props.history.push(
        searchStateToUrl(this.props, searchState),
        searchState
      );
    }, DEBOUNCE_TIME);

    this.setState({ searchState });
  };

  componentDidUpdate(e) {
    const exportBtn = document.getElementById('modalOpen');

    if (exportBtn) {
      if (e.length > 0) {
        exportBtn.classList.remove('disabledBtn');
      }
      if (e.length === 0) {
        exportBtn.classList.add('disabledBtn');
      }
    }
  }

  handleCheckallChange = () => {
    //get all checkboxes
    const checkboxes = document.querySelectorAll('[id=proje]');

    //cycle through boxes to check if they are checked
    for (let i = 0; i < checkboxes.length; i++) {
      //check if boxes are not checked
      if (checkboxes[i].checked === false) {
        //set all boxes checker
        checkboxes[i].checked = true;
      } else {
        checkboxes[i].checked = false;
      }
    }
  };

  setCalendar = (e) => {
    this.setState(() => ({
      calendar: true,
    }));
    document.getElementById('calender-icon').classList.add('active-icon');
    document.getElementById('list-icon').classList.remove('active-icon');
  };

  setList = (e) => {
    this.setState(() => ({
      calendar: false,
    }));
    document.getElementById('calender-icon').classList.remove('active-icon');
    document.getElementById('list-icon').classList.add('active-icon');
  };

  handleStartFilterChange = (e) => {
    this.setState(() => {
      return { startfilter: moment(e.target.value).valueOf() };
    });
  };

  handleEindFilterChange = (e) => {
    this.setState(() => {
      return { eindfilter: moment(e.target.value).valueOf() };
    });
  };

  render() {
    const { auth } = this.props; // eslint-disable-next-line
    const [events, setEvents] = this.context;
    this.componentDidUpdate(events);

    if (!auth.uid) return <Redirect to="/signin" />;
    return (
      <div className="dashboard container-wide">
        <div className="row">
          <div className="col s12">
            <div className="title">
              {moment().format('HH') < 12 ? (
                <>
                  <h1>Morning {this.props.profile.firstname},</h1>{' '}
                  <h6>Tijd om wat moois te organiseren!</h6>
                </>
              ) : moment().format('HH') >= 12 && moment().format('HH') < 18 ? (
                <>
                  <h1>Hey {this.props.profile.firstname},</h1>{' '}
                  <h6>Wat gaan we vanmiddag plannen?</h6>
                </>
              ) : (
                <>
                  <h1>Goeieavond {this.props.profile.firstname},</h1>{' '}
                  <h6>Let's get organising!</h6>
                </>
              )}
              <div id="toasty"></div>
            </div>
            <InstantSearch
              searchClient={searchClient}
              indexName="Events"
              searchState={this.state.searchState}
              onSearchStateChange={this.onSearchStateChange}
              createURL={createURL}
            >
              <Configure
                filters={`einddatum:${this.state.startfilter} TO ${this.state.eindfilter}`}
              />
              <div className="searchBar">
                <div className="actions">
                  <div className="utility-bar">
                    <SelectedHeaderList>
                      <div className="first-row">
                        {this.state.calendar === false && (
                          <>
                            <SearchBox
                              reset={<X size={20} />}
                              translations={{
                                placeholder: 'Zoek hier je evenement...',
                              }}
                            />
                            {this.props.profile.role !== 'gast' && (
                              <>
                                {isMobile === false && (
                                  <>
                                    <button
                                      className="btn-icon disabledBtn"
                                      id="modalOpen"
                                      onClick={() =>
                                        this.setState({
                                          exportshow: true,
                                        })
                                      }
                                    >
                                      <span>
                                        <Download size={20} />
                                      </span>
                                      Export
                                    </button>
                                    <ExportModal
                                      show={this.state.exportshow}
                                      onClose={() =>
                                        this.setState({
                                          exportshow: false,
                                        })
                                      }
                                    />
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}

                        <div className="icon-switcher">
                          <FiList
                            onClick={this.setList}
                            className="icon-button active-icon"
                            id="list-icon"
                          />
                          <SlCalender
                            onClick={this.setCalendar}
                            className="icon-button"
                            id="calender-icon"
                          />
                        </div>
                      </div>
                      {this.state.calendar === false && (
                        <div className="second-row">
                          <div className="date-filter">
                            <div className="date-container">
                              <input
                                type="date"
                                className="datum filter"
                                defaultValue={moment(
                                  this.state.startfilter
                                ).format('YYYY-MM-DD')}
                                onChange={this.handleStartFilterChange}
                              ></input>
                            </div>
                            <p>Tot</p>
                            <div className="date-container">
                              <input
                                type="date"
                                className="datum filter"
                                defaultValue={moment(
                                  this.state.eindfilter
                                ).format('YYYY-MM-DD')}
                                onChange={this.handleEindFilterChange}
                              ></input>
                            </div>
                          </div>
                          <SortBy
                            defaultRefinement="Events_replica"
                            items={[
                              { value: 'Events_replica', label: 'Sorteren op' },
                              {
                                value: 'Events_projectnaam_asc',
                                label: 'A -> Z',
                              },
                              {
                                value: 'Events_projectnaam_desc',
                                label: 'Z -> A',
                              },
                              { value: 'Events_status', label: 'Status' },
                              {
                                value: 'Events_capacity_asc',
                                label: 'Capaciteit ↑',
                              },
                              {
                                value: 'Events_capacity_desc',
                                label: 'Capaciteit ↓',
                              },
                            ]}
                          />
                          <RefinementList
                            attribute="status"
                            defaultRefinement={[
                              'Bevestigd',
                              'Concept',
                              'Cancelled',
                              'Feestdag',
                            ]}
                          />
                        </div>
                      )}
                    </SelectedHeaderList>
                  </div>
                  {isMobile === false && (
                    <>
                      {this.props.profile.role !== 'gast' && (
                        <div>
                          <button
                            className="event-add"
                            onClick={() =>
                              this.setState({
                                addshow: true,
                              })
                            }
                          >
                            <p>Evenement toevoegen</p>
                            <span>
                              <Plus size={20} />
                            </span>
                          </button>
                        </div>
                      )}
                    </>
                  )}
                  <EventAdd
                    show={this.state.addshow}
                    onClose={() =>
                      this.setState({
                        addshow: false,
                      })
                    }
                  />
                </div>
              </div>
              {this.state.calendar === false && (
                <div className="event-list">
                  <table className="striped">
                    <thead>
                      {this.props.profile.dashboardview && (
                        <tr>
                          {this.props.profile.role !== 'gast' && (
                            <>
                              {isMobile === false && (
                                <th>
                                  <SelectAll />
                                </th>
                              )}
                            </>
                          )}
                          {isMobile === true && <th>Evenementen</th>}
                          {this.props.profile.dashboardview.projectnaam ===
                            true && isMobile === false ? (
                            <th id="pNaam">Projectnaam</th>
                          ) : null}
                          {isMobile === false && (
                            <>
                              {this.props.profile.dashboardview.datums ===
                                true && <th id="dateC">Datum</th>}
                              {this.props.profile.dashboardview
                                .draaiboeklink === true && <th>Draaiboek</th>}
                              {this.props.profile.dashboardview.organisator ===
                                true && <th id="orgC">Organisator</th>}
                              {this.props.profile.dashboardview.partner ===
                                true && <th>Partner</th>}
                              {this.props.profile.dashboardview.plaats ===
                                true && <th>Plaats</th>}
                              {this.props.profile.dashboardview.provincie ===
                                true && <th>Provincie</th>}
                              {this.props.profile.dashboardview.venue ===
                                true && <th>Venue</th>}
                              {this.props.profile.dashboardview.adres ===
                                true && <th>Adres</th>}
                              {this.props.profile.dashboardview.capaciteit ===
                                true && <th>Capaciteit</th>}
                              {this.props.profile.dashboardview.duurzaamheid ===
                                true && <th>Duurzaamheid</th>}
                              {this.props.profile.dashboardview
                                .projecteigenaar === true && (
                                <th>Projecteigenaar</th>
                              )}
                              {this.props.profile.dashboardview
                                .projectmanager === true && (
                                <th>Projectmanager</th>
                              )}
                              {this.props.profile.dashboardview
                                .productiemanager === true && (
                                <th>Productiemanager</th>
                              )}
                              {this.props.profile.dashboardview
                                .marketingmanager === true && (
                                <th>Marketingmanager</th>
                              )}
                              {this.props.profile.dashboardview
                                .leveranciercrew === true && (
                                <th>Leverancier crew show</th>
                              )}
                              {this.props.profile.dashboardview
                                .horecamanager === true && (
                                <th>Horecamanager</th>
                              )}
                              {this.props.profile.dashboardview
                                .horecamanagershow === true && (
                                <th>Horecamanager (show)</th>
                              )}
                              {this.props.profile.dashboardview.programmeur ===
                                true && <th>Programmeur</th>}
                              {this.props.profile.dashboardview
                                .financemanager === true && (
                                <th>Financemanager</th>
                              )}
                              {this.props.profile.dashboardview
                                .financeticketingmanager === true && (
                                <th>Finance &#38; ticketing manager</th>
                              )}
                              {this.props.profile.dashboardview
                                .administratie === true && (
                                <th>Administratie</th>
                              )}
                              {this.props.profile.dashboardview
                                .barleverancier === true && (
                                <th>Bar leverancier</th>
                              )}
                              {this.props.profile.dashboardview
                                .restleverancier === true && (
                                <th>Restleverancier</th>
                              )}
                              {this.props.profile.dashboardview.brouwerij ===
                                true && <th>Brouwerij</th>}
                              {this.props.profile.dashboardview.beveiliging ===
                                true && <th>Beveiliging</th>}
                              {this.props.profile.dashboardview.transport ===
                                true && <th>Transport</th>}
                              {this.props.profile.dashboardview.tourset ===
                                true && <th>Tourset</th>}
                              {this.props.profile.dashboardview
                                .decorproducent === true && (
                                <th>Decor / Tech. Producent</th>
                              )}
                              {this.props.profile.dashboardview.opmerkingen ===
                                true && <th>Opmerkingen</th>}
                              {this.props.profile.dashboardview.status ===
                                true && <th>Status</th>}
                            </>
                          )}
                        </tr>
                      )}
                    </thead>
                    <InfiniteHits
                      showPrevious={true}
                      hitComponent={Hit}
                      cache={sessionStorageCache}
                    />
                  </table>
                </div>
              )}
            </InstantSearch>
            {this.state.calendar === true && <BigCalendar />}
          </div>
        </div>
      </div>
    );
  }
}

function Hit(props) {
  const [events, setEvents] = useContext(selectedEventsContext);
  const [role, setRole] = useState('');
  const [view, setView] = useState('');
  const [copyUser, setCopyUser] = useState('');

  const getUserRole = () => {
    firebase
      .firestore()
      .collection('users')
      .doc(firebase.auth().currentUser.uid)
      .get()
      .then((snapshot) => {
        setRole(snapshot.data().role);
      });
  };

  const getDashboardView = () => {
    firebase
      .firestore()
      .collection('users')
      .doc(firebase.auth().currentUser.uid)
      .get()
      .then((snapshot) => {
        setView(snapshot.data().dashboardview);
      });
  };

  const eventDelete = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui" id="confirmDelete">
            <h1>Weet je het zeker?</h1>
            <p>Dat je dit evenement wilt verwijderen?</p>
            <button className="button" onClick={onClose}>
              Nee
            </button>
            <button
              className="button"
              onClick={deleteEvenement(props.hit.objectID)}
            >
              Verwijderen
            </button>
          </div>
        );
      },
    });
  };

  const getUser = () => {
    firebase
      .firestore()
      .collection('users')
      .doc(firebase.auth().currentUser.uid)
      .get()
      .then((doc) => {
        setCopyUser(doc.data());
      });
  };

  if (copyUser === '') {
    getUser();
  }

  const eventCopy = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui" id="confirmCopy">
            <h1>Weet je het zeker?</h1>
            <p>Dat je dit evenement wilt kopiëren?</p>
            <button className="button" onClick={onClose}>
              Nee
            </button>
            <button
              className="button"
              onClick={copyEvenement(props.hit, copyUser)}
            >
              kopiëren
            </button>
          </div>
        );
      },
    });
  };

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      // Get full event
      setEvents([...events, props.hit]);
    } else {
      // Remove event if not checked but still in list
      setEvents(events.filter((evnt) => evnt !== props.hit));
    }
  };

  useEffect(() => {
    getUserRole();
    getDashboardView();
  }, []);

  return (
    <>
      {isMobile === false && role !== 'gast' ? (
        <td key={props.hit.objectID}>
          <p>
            <label>
              <input
                type="checkbox"
                id="proje"
                className="filled-in CHCH"
                defaultChecked={false}
                onChange={handleCheckboxChange}
                value={props.hit.objectID}
              />
              <span></span>
            </label>
          </p>
        </td>
      ) : null}
      {isMobile === true && (
        <td
          id="dateC"
          style={{
            textAlign: 'center',
            fontWeight: 'bold',
            color: '#2a0b3a',
          }}
        >
          <p style={{ margin: '0' }}>
            {props.hit.startdatum === props.hit.einddatum &&
              moment(props.hit.startdatum).format('D')}
            {props.hit.startdatum !== props.hit.einddatum &&
              moment(props.hit.startdatum).format('D') + ' / '}
            {props.hit.startdatum !== props.hit.einddatum &&
              moment(props.hit.einddatum).format('D')}
          </p>
          <p>
            {moment(props.hit.startdatum).format('MMM') ===
              moment(props.hit.einddatum).format('MMM') &&
              moment(props.hit.einddatum).format('MMM')}
            {moment(props.hit.startdatum).format('MMM') !==
              moment(props.hit.einddatum).format('MMM') &&
              moment(props.hit.startdatum).format('MMM') +
                ' / ' +
                moment(props.hit.einddatum).format('MMM')}
          </p>
        </td>
      )}
      {view.projectnaam === true && (
        <>
          {props.hit.duurzaamheid ? (
            <td>
              {props.hit.projectnaam}{' '}
              {props.hit.duurzaamheid === null ? (
                <></>
              ) : props.hit.duurzaamheid === 'GreenBasic' ? (
                <GiThreeLeaves style={{ color: 'green' }} />
              ) : props.hit.duurzaamheid === 'Green+' ? (
                <p style={{ color: 'green', display: 'inline-block' }}>
                  <GiThreeLeaves />+
                </p>
              ) : props.hit.duurzaamheid === 'Green++' ? (
                <p style={{ color: 'green', display: 'inline-block' }}>
                  <GiThreeLeaves />
                  ++
                </p>
              ) : (
                <></>
              )}
            </td>
          ) : (
            <td>{props.hit.projectnaam}</td>
          )}
        </>
      )}
      {isMobile === false && (
        <>
          {view.datums === true && (
            <td id="dateC">
              {props.hit.startdatum === props.hit.einddatum &&
                moment(props.hit.startdatum).format('L')}
              {props.hit.startdatum !== props.hit.einddatum &&
                moment(props.hit.startdatum).format('L') + ' / '}
              <br />
              {props.hit.startdatum !== props.hit.einddatum &&
                moment(props.hit.einddatum).format('L')}
            </td>
          )}
          {view.draaiboeklink === true && <td>{props.hit.draaiboeklink}</td>}
          {view.organisator === true && (
            <td id="orgC">{props.hit.organisator}</td>
          )}
          {view.partner === true && <td>{props.hit.partner}</td>}
          {view.plaats === true && <td>{props.hit.plaats}</td>}
          {view.venue === true && <td>{props.hit.venue}</td>}
          {view.adres === true && <td>{props.hit.adres}</td>}
          {view.capaciteit === true && <td>{props.hit.capaciteit}</td>}
          {view.duurzaamheid === true && <td>{props.hit.duurzaamheid}</td>}
          {view.projecteigenaar === true && (
            <td>{props.hit.projecteigenaar}</td>
          )}
          {view.projectmanager === true && <td>{props.hit.projectmanager}</td>}
          {view.productiemanager === true && (
            <td>{props.hit.productiemanager}</td>
          )}
          {view.marketingmanager === true && (
            <td>{props.hit.marketingmanager}</td>
          )}
          {view.leveranciercrew === true && (
            <td>{props.hit.leveranciercrew}</td>
          )}
          {view.horecamanager === true && <td>{props.hit.horecamanager}</td>}
          {view.horecamanagershow === true && (
            <td>{props.hit.horecamanagershow}</td>
          )}
          {view.programmeur === true && <td>{props.hit.programmeur}</td>}
          {view.financemanager === true && <td>{props.hit.financemanager}</td>}
          {view.financeticketingmanager === true && (
            <td>{props.hit.financeticketingmanager}</td>
          )}
          {view.administratie === true && <td>{props.hit.administratie}</td>}
          {view.barleverancier === true && <td>{props.hit.barleverancier}</td>}
          {view.restleverancier === true && (
            <td>{props.hit.restleverancier}</td>
          )}
          {view.brouwerij === true && <td>{props.hit.brouwerij}</td>}
          {view.beveiliging === true && <td>{props.hit.beveiliging}</td>}
          {view.transport === true && <td>{props.hit.transport}</td>}
          {view.tourset === true && <td>{props.hit.tourset}</td>}
          {view.decorproducent === true && <td>{props.hit.decorproducent}</td>}
          {view.opmerkingen === true && <td>{props.hit.opmerkingen}</td>}
          {view.status === true && (
            <td>
              {props.hit.status === 'Bevestigd' && (
                <p className="status-block confirmed">{props.hit.status}</p>
              )}
              {props.hit.status === 'Concept' && (
                <p className="status-block concept">{props.hit.status}</p>
              )}
              {props.hit.status === 'Cancelled' && (
                <p className="status-block cancelled">{props.hit.status}</p>
              )}
              {props.hit.status === 'Feestdag' && (
                <p className="status-block feestdag">{props.hit.status}</p>
              )}
            </td>
          )}
        </>
      )}
      <td className="i-icons">
        <Link to={'/evenement/' + props.hit.objectID}>
          <Eye />
        </Link>
        {isMobile === false && (
          <>
            {/* eslint-disable-next-line */}
            {role === 'beheerder' && ( // eslint-disable-next-line
              <a>
                <Trash id={props.hit.objectID} onClick={eventDelete} />
              </a>
            )}

            {role !== 'gast' && ( // eslint-disable-next-line
              <a>
                <Copy id={props.hit.objectID} onClick={eventCopy} />
              </a>
            )}
          </>
        )}
      </td>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteEvenement: (id) => {
      dispatch(deleteEvenement(id));
    },
    copyEvenement: (evenement) => {
      dispatch(copyEvenement(evenement));
    },
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  Dashboard,
  Hit
);
