import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { signOut } from '../../store/actions/authActions';
import { UserPlus, LogOut, Info, Bell, DollarSign } from 'react-feather';
import NotificationModal from '../models/NotificationModal';

const SignedInLinks = (props) => {
  const notifications = props.notificaties;

  const openNotificationModal = () => {
    const modal = document.getElementById('notify');
    if (modal.classList.length === 3) {
      modal.classList.remove('hidden');
    } else {
      modal.classList.add('hidden');
    }
  };

  const openModal = () => {
    const modal = document.getElementById('help');
    if (modal.classList.length === 2) {
      modal.classList.remove('hidden');
    } else {
      modal.classList.add('hidden');
    }
  };

  return (
    <ul>
      {props.profile.role === 'beheerder' && (
        <li>
          <Link to={'/signup'} className="navBtn-icon">
            <UserPlus size={20} />
          </Link>
        </li>
      )}
      <li>
        <button className="navBtn-icon" onClick={props.signOut}>
          <LogOut size={20} />
        </button>
      </li>
      {props.profile.role !== 'gast' && (
        <li>
          <button className="navBtn-icon" onClick={openNotificationModal}>
            <Bell size={20} />
          </button>
          <NotificationModal notifications={notifications} />
        </li>
      )}
      {props.profile.role !== 'gast' && (
        <li>
          <Link to={'/facturen'} className="navBtn-icon">
            <DollarSign size={20} />
          </Link>
        </li>
      )}
      <li>
        <NavLink to={'/account'} className="btn btn-floating">
          {props.profile.initials}
        </NavLink>
      </li>
      <li>
        <button className="navBtn-icon" onClick={openModal}>
          <Info size={22} />
        </button>
      </li>
      <div id="help" className="help-block hidden">
        <h1>Hulp nodig?</h1>
        <p>Neem contact op via:</p>
        <a href="mailto:bart@thisislive.group">bart@thisislive.group</a>
      </div>
    </ul>
  );
};

const mapStateToProps = (state) => {
  return {
    notificaties: state.firestore.ordered.notificaties,
  };
};

const mapDistpatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default compose(
  connect(mapStateToProps, mapDistpatchToProps),
  firestoreConnect([
    { collection: 'notificaties', limit: 15, orderBy: ['time', 'desc'] },
  ])
)(SignedInLinks);
