import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signIn } from '../../store/actions/authActions';
import { Link, Redirect } from 'react-router-dom';
import TILogo from '../../images/TILogo.svg';

class Signin extends Component {
  state = {
    email: '',
    password: '',
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.signIn(this.state);
  };

  render() {
    const { authError, auth } = this.props;

    if (auth.uid) return <Redirect to="/" />;
    return (
      <div className="flex-container-height">
        <div className="image-round"></div>
        <div className="login-container">
          <form onSubmit={this.handleSubmit}>
            <div className="form-title">
              <div>
                <img className="logo-big" src={TILogo} alt="TIL Logo" />
                <h5>EVENTPLANNING</h5>
              </div>
            </div>
            <div className="input-field">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                onChange={this.handleChange}
              ></input>
            </div>
            <div className="input-field">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                onChange={this.handleChange}
              ></input>
            </div>
            <div className="submit-bar">
              <Link to={'/forgot-password'} className="fgPwd">
                Wachtwoord vergeten?
              </Link>
              <div>
                <button className="button">Login</button>
                <div className="red-text center">
                  {authError ? <p>{authError}</p> : null}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
