import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Dashboard from './components/dashboard/Dashboard';
import EvenementDetails from './components/evenement/EvenementDetails';
import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';
import CreateEvenement from './components/evenement/CreateEvenement';
import QuickCreateEvenement from './components/evenement/QuickCreateEvenement';
import EditEvenement from './components/evenement/EditEvenement';
import SelectedEventList from './context/SelectedEventList';
import ForgotPwd from './components/auth/ForgotPwd';
import Account from './components/auth/Account';
import NotifyLogs from './components/Notifications/NotifyLogs';
import FacturenOverview from './components/facturen/FacturenOverview';
import EditFacturen from './components/facturen/EditFacturen';
// import Confetti from 'react-confetti';
// import Snowfall from 'react-snowfall';

class App extends Component {
  render() {
    let nav;
    let festive;
    const { auth, profile } = this.props;

    // if user is logged in show navigationbar
    if (auth.uid) {
      nav = <Navbar />;
    }

    if (profile.festiveView) {
      // festive = <Confetti opacity={0.8} />;
      // festive = <Snowfall />;
    }

    return (
      <BrowserRouter>
        <div className="App">
          {festive}
          <SelectedEventList>
            {nav}
            <Switch>
              <Route exact path="/" component={Dashboard} />
              <Route path="/evenement/:id" component={EvenementDetails} />
              <Route path="/facturen" component={FacturenOverview} />
              <Route path="/edit-facturen/:id" component={EditFacturen} />
              <Route path="/create" component={CreateEvenement} />
              <Route path="/quick-create" component={QuickCreateEvenement} />
              <Route path="/update/:id" component={EditEvenement} />
              <Route path="/signin" component={SignIn} />
              <Route path="/signup" component={SignUp} />
              <Route path="/forgot-password" component={ForgotPwd} />
              <Route path="/account" component={Account} />
              <Route path="/notifylog" component={NotifyLogs} />
            </Switch>
          </SelectedEventList>
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

export default connect(mapStateToProps)(App);
