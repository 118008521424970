import React, { useEffect, useState } from 'react';

import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import listPlugin from '@fullcalendar/list';

import { useHistory } from 'react-router-dom';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import firebase from 'firebase/compat/app';
import { isMobile } from 'react-device-detect';

export default function BigCalendar() {
  const [Cevents, setCevents] = useState([]);
  var snapshot = firebase.firestore().collection('evenementen').get();

  useEffect(() => {
    snapshot.then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setCevents((Cevents) => [
          ...Cevents,
          {
            title: doc.data().projectnaam,
            start:
              moment(doc.data().startdatum).format() !== 'Invalid date'
                ? moment(doc.data().startdatum).format()
                : moment(doc.data().startdatum.toDate()).format(),
            end:
              moment(doc.data().einddatum).add(1, 'd').format() !==
              'Invalid date'
                ? moment(doc.data().einddatum).add(1, 'd').format()
                : moment(doc.data().einddatum.toDate()).add(1, 'd').format(),
            allDay: true,
            status: doc.data().status,
            id: doc.data().id,
            backgroundColor:
              doc.data().status === 'Bevestigd'
                ? '#09df26'
                : doc.data().status === 'Concept'
                ? '#dad414'
                : doc.data().status === 'Cancelled'
                ? '#ff0000'
                : '#7500c2',
            borderColor:
              doc.data().status === 'Bevestigd'
                ? '#09df26'
                : doc.data().status === 'Concept'
                ? '#dad414'
                : doc.data().status === 'Cancelled'
                ? '#ff0000'
                : '#7500c2',
          },
        ]);
      });
    }); // eslint-disable-next-line
  }, []);

  const history = useHistory();

  return (
    <div className="calendar-wrap">
      <FullCalendar
        plugins={[dayGridPlugin, listPlugin]}
        initialView={isMobile ? 'listWeek' : 'dayGridMonth'}
        events={Cevents}
        firstDay={1}
        locale="nl"
        weekNumbers={true}
        weekNumberFormat={{ week: 'numeric' }}
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
        }}
        displayEventEnd={true}
        nextDayThreshold={'03:30:00'}
        eventClick={(event) => {
          history.push('/evenement/' + event.event.id);
        }}
      />
    </div>
  );
}
