import { uploadPhoto } from './imgActions';
import firebase from 'firebase/compat/app';

export const createEvenement = (evenement, img) => {
  return (dispatch, getState) => {
    const creatorId = getState().firebase.auth.uid;
    const creatorProfile = getState().firebase.profile;

    let imgRef = { fullPath: 'images/default.jpg' };
    if (typeof img !== 'undefined') {
      imgRef = uploadPhoto(img, img.name);
      console.log(img);
    }
    // make Async call to database
    firebase
      .firestore()
      .collection('evenementen')
      .add({
        ...evenement,
        img: imgRef.fullPath,
        createdBy: creatorProfile.firstname + ' ' + creatorProfile.lastname,
        creatorUID: creatorId,
        factuuren: [],
      })
      .then(() => {
        dispatch({ type: 'CREATE_EVENEMENT', evenement });
      })
      .catch((err) => {
        dispatch({ type: 'CREATE_EVENEMENT_ERROR', err });
      });
  };
};

export const copyEvenement = (evenement, copyUser) => {
  return (dispatch) => {
    // make Async call to database
    firebase
      .firestore()
      .collection('evenementen')
      .add({
        administratie: evenement.administratie ? evenement.administratie : null,
        adres: evenement.adres ? evenement.adres : null,
        barleverancier: evenement.barleverancier
          ? evenement.barleverancier
          : null,
        beveiliging: evenement.beveiliging ? evenement.beveiliging : null,
        brouwerij: evenement.brouwerij ? evenement.brouwerij : null,
        capaciteit: evenement.capaciteit ? evenement.capaciteit : null,
        einddatum: evenement.einddatum ? evenement.einddatum : null,
        extraInput: evenement.extraInput ? evenement.extraInput : null,
        financemanager: evenement.financemanager
          ? evenement.financemanager
          : null,
        horecamanager: evenement.horecamanager ? evenement.horecamanager : null,
        horecamanagershow: evenement.horecamanagershow
          ? evenement.horecamanagershow
          : null,
        img: evenement.img ? evenement.img : null,
        locatiemanager: evenement.locatiemanager
          ? evenement.locatiemanager
          : null,
        marketingmanager: evenement.marketingmanager
          ? evenement.marketingmanager
          : null,
        medisch: evenement.medisch ? evenement.medisch : null,
        opmerkingen: evenement.opmerkingen ? evenement.opmerkingen : null,
        organisator: evenement.organisator ? evenement.organisator : null,
        partner: evenement.partner ? evenement.partner : null,
        plaats: evenement.plaats ? evenement.plaats : null,
        productiemanager: evenement.productiemanager
          ? evenement.productiemanager
          : null,
        programmeur: evenement.programmeur ? evenement.programmeur : null,
        projecteigenaar: evenement.projecteigenaar
          ? evenement.projecteigenaar
          : null,
        projectmanager: evenement.projectmanager
          ? evenement.projectmanager
          : null,
        projectnaam: evenement.projectnaam + '(copy)',
        restleverancier: evenement.restleverancier
          ? evenement.restleverancier
          : null,
        showtijden: evenement.showtijden ? evenement.showtijden : null,
        startdatum: evenement.startdatum ? evenement.startdatum : null,
        status: evenement.status ? evenement.status : null,
        venue: evenement.venue ? evenement.venue : null,
        wetgeving: evenement.wetgeving ? evenement.wetgeving : null,
        locatiemanagertel: evenement.locatiemanagertel
          ? evenement.locatiemanagertel
          : null,
        locatiemanagermail: evenement.locatiemanagermail
          ? evenement.locatiemanagermail
          : null,
        beverageassortiment: evenement.beverageassortiment
          ? evenement.beverageassortiment
          : null,
        beverageactivaties: evenement.beverageactivaties
          ? evenement.beverageactivaties
          : null,
        beveragespecialsbier: evenement.beveragespecialsbier
          ? evenement.beveragespecialsbier
          : null,
        beverageshirt: evenement.beverageshirt ? evenement.beverageshirt : null,
        muntprijs: evenement.muntprijs ? evenement.muntprijs : null,
        horecaopbouw: evenement.horecaopbouw ? evenement.horecaopbouw : null,
        horecaafbouw: evenement.horecaafbouw ? evenement.horecaafbouw : null,
        updatedBy: null,
        updaterUID: null,
        createdBy: copyUser.firstname + ' ' + copyUser.lastname,
        creatorUID: null,
        factuuren: [],
        duurzaamheid: evenement.duurzaamheid ? evenement.duurzaamheid : null,
        draaiboeklink: evenement.draaiboeklink ? evenement.draaiboeklink : null,
      })
      .then(() => {
        // dispatch({type: 'COPIED_EVENEMENT', evenement})
        console.log('Copied_event');
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((err) =>
        // dispatch({type: 'COPIED_EVENEMENT_ERROR', err})
        console.log(err)
      );
  };
};

export const editEvenement = (evenement, id) => {
  return (dispatch, getState) => {
    const updaterId = getState().firebase.auth.uid;
    const updaterProfile = getState().firebase.profile;

    // make Async call to database
    firebase
      .firestore()
      .collection('evenementen')
      .doc(id)
      .update({
        ...evenement,
        updatedBy: updaterProfile.firstname + ' ' + updaterProfile.lastname,
        updaterUID: updaterId,
        factuuren: [],
      })
      .then(() => {
        dispatch({ type: 'UPDATE_EVENEMENT', evenement });
      })
      .catch((err) => {
        dispatch({ type: 'UPDATE_EVENEMENT_ERROR', err });
      });
  };
};

export const editEvenementImage = (evenement, id, img) => {
  return (dispatch, getState) => {
    let imgRef = { fullPath: 'images/default.jpg' };
    if (typeof img !== 'undefined') {
      imgRef = uploadPhoto(img.img, img.img.name);
    }

    // make Async call to database
    firebase
      .firestore()
      .collection('evenementen')
      .doc(id)
      .update({
        ...evenement,
        img: imgRef.fullPath,
        factuuren: [],
      })
      .then(() => {
        dispatch({ type: 'UPDATE_EVENEMENT_IMG', evenement });
      })
      .catch((err) => {
        dispatch({ type: 'UPDATE_EVENEMENT_IMG_ERROR', err });
      });
  };
};

export const deleteEvenement = (id) => {
  return (dispatch, getState) => {
    // make Async call to database
    firebase.firestore().collection('evenementen').doc(id).delete();

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };
};
