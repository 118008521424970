import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import firebase from 'firebase/compat/app';
import FactuurBlock from './FactuurBlock';
import { ArrowLeft } from 'react-feather';
import toast, { Toaster } from 'react-hot-toast';

const factuurmodal = {
  title: '',
  adres: '',
  bank: '',
  btw: '',
  facPdf: '',
  facVra: '',
  kvk: '',
};

const notify = () =>
  toast.success(
    'Factuur adres is aangemaakt, refresh de pagina als hij nog niet zichtbaar is in de lijst.'
  );

export const FacturenOverview = (props) => {
  const { profile } = props;
  const [newFactuur, setNewFactuur] = useState(factuurmodal);
  const [factuurList, setFactuurList] = useState([]);

  const setFactuurListData = () => {
    var factuurRef = firebase
      .firestore()
      .collection('facturen')
      .orderBy('title');

    factuurRef.get().then((querySnapshot) => {
      var factuurDataList = [];
      querySnapshot.forEach((doc) => {
        factuurDataList.push({ id: doc.id, ...doc.data() });
      });
      setFactuurList(factuurDataList);
    });
  };

  useEffect(() => {
    setFactuurListData();
  }, []);

  const handleBack = (e) => {
    e.preventDefault();
    window.history.back();
  };

  const handleChange = (e) => {
    setNewFactuur({
      ...newFactuur,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    firebase.firestore().collection('facturen').add(newFactuur);
    setFactuurListData();
    notify();
  };

  return (
    <div className="dashboard container-wide">
      <div className="row">
        <div className="col s12">
          <Toaster />
          <button className="btn-icon back-btn" onClick={handleBack}>
            <span>
              <ArrowLeft size={20} />
            </span>
            Back
          </button>
          <div className="title">
            <h1>FACTUREN</h1>
          </div>
          <div className="container-wide-split">
            <div className="split-alt-1">
              <form
                className="full-width"
                onSubmit={handleSubmit}
                style={{
                  justifyContent: 'space-evenly',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  padding: '0px',
                }}
              >
                <div className="input-field big">
                  <label htmlFor="title">Naam BV</label>
                  <input type="text" id="title" onChange={handleChange}></input>
                </div>
                <div className="input-field big">
                  <label htmlFor="adres">Adres</label>
                  <input type="text" id="adres" onChange={handleChange}></input>
                </div>
                <div className="input-field big">
                  <label htmlFor="bank">Bankrekening nummer</label>
                  <input type="text" id="bank" onChange={handleChange}></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="kvk">KVK-nummer</label>
                  <input type="text" id="kvk" onChange={handleChange}></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="btw">BTW-nummer</label>
                  <input type="text" id="btw" onChange={handleChange}></input>
                </div>
                <div className="input-field big">
                  <label htmlFor="facPdf">Factuur PDF email</label>
                  <input
                    type="text"
                    id="facPdf"
                    onChange={handleChange}
                  ></input>
                </div>
                <div className="input-field big">
                  <label htmlFor="facVra">Factuur vragen email</label>
                  <input
                    type="text"
                    id="facVra"
                    onChange={handleChange}
                  ></input>
                </div>
                <button className="button">Factuur adres toevoegen</button>
              </form>
              {console.log(profile)}
            </div>
            <div
              className="split-alt-2"
              style={{
                justifyContent: 'flex-start',
                height: '65vh',
                overflowY: 'auto',
              }}
            >
              {factuurList !== [] && (
                <>
                  {factuurList.map((factuur) => {
                    return (
                      <FactuurBlock
                        title={factuur.title}
                        id={factuur.id}
                        authorised={profile.role === 'beheerder' ? true : false}
                      />
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    facturen: state.firestore.data.facturen,
  };
};

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    {
      collection: 'facturen',
    },
  ])
)(FacturenOverview);
