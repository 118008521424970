import React, { useState } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import toast, { Toaster } from 'react-hot-toast';
import firebase from 'firebase/compat/app';
import { ArrowLeft } from 'react-feather';

const notify = () =>
  toast.success('Factuur is bijgewerkt, je wordt automatisch teruggestuurd.');

export const FacturenOverview = (props) => {
  const { factuur } = props;
  const [newFactuur, setNewFactuur] = useState(factuur);

  const handleBack = (e) => {
    e.preventDefault();
    window.history.back();
  };

  const handleChange = (e) => {
    setNewFactuur({
      ...newFactuur,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(props.match.params.id);
    firebase
      .firestore()
      .collection('facturen')
      .doc(props.match.params.id)
      .update(newFactuur);

    notify();
    setTimeout(() => {
      window.history.back();
    }, 3000);
  };

  return (
    <div className="dashboard container-wide">
      <div className="row">
        <div className="col s12">
          <Toaster />
          <button className="btn-icon back-btn" onClick={handleBack}>
            <span>
              <ArrowLeft size={20} />
            </span>
            Back
          </button>
          <div className="title">
            <h1>FACTUUR BEWERKEN</h1>
          </div>
          <div className="container-wide-split">
            <div className="split-alt-1">
              <form
                className="full-width"
                onSubmit={handleSubmit}
                style={{
                  justifyContent: 'space-evenly',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  padding: '0px',
                }}
              >
                <div className="input-field big">
                  <label className="active" htmlFor="title">
                    Naam BV
                  </label>
                  <input
                    type="text"
                    id="title"
                    onChange={handleChange}
                    defaultValue={factuur.title}
                  ></input>
                </div>
                <div className="input-field big">
                  <label className="active" htmlFor="adres">
                    Adres
                  </label>
                  <input
                    type="text"
                    id="adres"
                    onChange={handleChange}
                    defaultValue={factuur.adres}
                  ></input>
                </div>
                <div className="input-field big">
                  <label className="active" htmlFor="bank">
                    Bankrekening nummer
                  </label>
                  <input
                    type="text"
                    id="bank"
                    onChange={handleChange}
                    defaultValue={factuur.bank}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="kvk">
                    KVK-nummer
                  </label>
                  <input
                    type="text"
                    id="kvk"
                    onChange={handleChange}
                    defaultValue={factuur.kvk}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="btw">
                    BTW-nummer
                  </label>
                  <input
                    type="text"
                    id="btw"
                    onChange={handleChange}
                    defaultValue={factuur.btw}
                  ></input>
                </div>
                <div className="input-field big">
                  <label className="active" htmlFor="facPdf">
                    Factuur PDF email
                  </label>
                  <input
                    type="text"
                    id="facPdf"
                    onChange={handleChange}
                    defaultValue={factuur.facPdf}
                  ></input>
                </div>
                <div className="input-field big">
                  <label className="active" htmlFor="facVra">
                    Factuur vragen email
                  </label>
                  <input
                    type="text"
                    id="facVra"
                    onChange={handleChange}
                    defaultValue={factuur.facVra}
                  ></input>
                </div>
                <button className="button">Factuur adres bewerken</button>
              </form>
            </div>
            <div
              className="split-alt-2"
              style={{
                justifyContent: 'flex-start',
                height: '65vh',
                overflowY: 'auto',
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const facturen = state.firestore.data.facturen;
  const factuur = facturen ? facturen[id] : null;
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    factuur: factuur,
  };
};

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    {
      collection: 'facturen',
    },
  ])
)(FacturenOverview);
