import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createEvenement } from '../../store/actions/evenementActions.js';
import firebase from 'firebase/compat/app';
import { Redirect } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { ArrowLeft } from 'react-feather';
import { Timestamp } from 'firebase/firestore';

const notify = () =>
  toast.success('Evenement is aangemaakt, je wordt automatisch doorgestuurd.');

class QuickCreateEvenement extends Component {
  state = {
    projectnaam: '',
    draaiboeklink: null,
    startdatum: '',
    einddatum: '',
    organisator: '',
    partner: null,
    plaats: '',
    venue: null,
    capaciteit: null,
    adres: null,
    status: '',
    projecteigenaar: null,
    projectmanager: null,
    marketingmanager: null,
    productiemanager: null,
    horecamanager: null,
    programmeur: null,
    financemanager: null,
    financeticketingmanager: null,
    administratie: null,
    eventmanager: null,
    leveranciercrew: null,
    opmerkingen: null,
    img: 'images/default.jpg',
    showtijden: null,
    horecamanagershow: null,
    barleverancier: null,
    brouwerij: null,
    restleverancier: null,
    beveiliging: null,
    medisch: null,
    wetgeving: null,
    locatiemanager: null,
    transport: null,
    tourset: null,
    beverageassortiment: null,
    beverageactivaties: null,
    beveragespecialsbier: null,
    beverageshirt: null,
    muntprijs: null,
    horecaopbouw: null,
    horecashow: null,
    horecaafbouw: null,
    cateraar: null,
    decorproducent: null,
    factuuren: [],
    duurzaamheid: null,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleBack = (e) => {
    e.preventDefault();
    window.history.back();
  };

  dateChange = (e) => {
    let date = new Date(e.target.value + 'T00:00:00');
    let fbTimestamp = Timestamp.fromDate(date);
    this.setState({
      [e.target.id]: fbTimestamp,
    });
  };

  getFacturen = () => {
    firebase
      .firestore()
      .collection('facturen')
      .onSnapshot((snapshot) => {
        snapshot.docs.forEach((doc) => {
          this.setState({
            factuuren: [...this.state.factuuren, doc.data()],
          });
        });
      });
  };

  componentDidMount() {
    this.getFacturen();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.createEvenement(this.state, this.state.img);
    notify();
    setTimeout(() => {
      this.props.history.push('/');
    }, 3000);
  };
  render() {
    const { auth } = this.props;

    if (!auth.uid) return <Redirect to="/signin" />;

    return (
      <div className="flex-container">
        <div className="container-wide">
          <button className="btn-icon back-btn" onClick={this.handleBack}>
            <span>
              <ArrowLeft size={20} />
            </span>
            Back
          </button>
          <form onSubmit={this.handleSubmit} className="full-width">
            <div className="form-title">
              <div>
                <h5>SNEL TOEVOEGEN</h5>
                <h6>NIEUW EVENEMENT</h6>
              </div>
              <div>
                <button className="button" type="submit">
                  Evenement toevoegen
                </button>
                <Toaster />
              </div>
            </div>

            <div className="input-field small">
              <label htmlFor="projectnaam">Projectnaam*</label>
              <input
                type="text"
                id="projectnaam"
                onChange={this.handleChange}
                required
              ></input>
            </div>
            <div className="input-field small">
              <select
                className="browser-default"
                onChange={this.handleChange}
                id="status"
              >
                <option value="" disabled selected>
                  Status*
                </option>
                <option value="Bevestigd">Bevestigd</option>
                <option value="Concept">Concept</option>
                <option value="Cancelled">Cancelled</option>
                <option value="Feestdag">Feestdag</option>
              </select>
            </div>
            <div className="input-field small">
              <input
                type="date"
                id="startdatum"
                onChange={this.dateChange}
                required
              ></input>
            </div>
            <div className="input-field small">
              <input
                type="date"
                id="einddatum"
                onChange={this.dateChange}
                required
              ></input>
            </div>
            <div className="input-field small">
              <select
                className="browser-default"
                onChange={this.handleChange}
                id="organisator"
              >
                <option value="" disabled selected>
                  Organisatie*
                </option>
                {this.state.factuuren !== null && (
                  <>
                    {this.state.factuuren.map((factuur) => {
                      return (
                        <option key={factuur.id} value={factuur.title}>
                          {factuur.title}
                        </option>
                      );
                    })}
                  </>
                )}
              </select>
            </div>
            <div className="input-field small">
              <label htmlFor="plaats">Plaats</label>
              <input
                type="text"
                id="plaats"
                onChange={this.handleChange}
              ></input>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    facturen: state.firestore.ordered.facturen,
  };
};

const mapDistpatchToProps = (dispatch) => {
  return {
    createEvenement: (evenement, img) =>
      dispatch(createEvenement(evenement, img)),
  };
};

export default connect(
  mapStateToProps,
  mapDistpatchToProps
)(QuickCreateEvenement);
